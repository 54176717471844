import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from 'react'
import { errorPageRoute } from 'src/routes/constants'
import {
  CreateLeaveStage,
  ILeaveGroupWithTypes,
  IWithQueriesProps,
  IWithToastManager,
  UserIntent
} from 'src/react-app-env'
import withQueries from 'src/components/HOC/withQueries'
import { withToastManager } from 'src/components/ToastManager'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { MetricEventWelcomeScreenImpression } from 'src/constants/metrics'
import useCreateMetric from 'src/graphql/hooks/useCreateMetric'
import { getAdjustedDateByWorkingDays } from 'src/utils/dateUtils'
import usePageTitle from 'src/components/hooks/usePageTitle'
import styled, { css } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import * as Methods from './methods'
import LeaveContext, {
  ICreateLeaveContext,
  MedicalWorkRelatedInjury,
  TpaApprovalState,
  TpaLeaveState
} from './context'
import { getCountryCode, isRoche } from 'src/utils/userUtils'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import SharedContext from 'src/contexts/SharedContext'
import { capitalize, isEqual } from 'lodash'
import { TIMELINE_REVIEW_ALERTS_KEY } from 'src/utils/ls'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import { ICustomerLeaveCreateConfig } from 'src/config/customers/config'
import { ProgressBar } from './components/ProgressBar'

interface IProps extends IWithQueriesProps, IWithToastManager {}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => (props.theme.isDesktop ? '24px' : '17px')};
  position: relative;
  z-index: ${zIndex.createLeave.page};

  ${props =>
    props.theme.isMobile &&
    css`
      height: calc(100% - 16px);
    `}
`

export const CreateLeaveContainer = React.memo((props: IProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { customer, customerConfig } = useContext(SharedContext)

  useRedirectByUserRole({ navigate, allowedRole: 'employee' })
  const [, setShowReviewAlert] = useLocalStorage(TIMELINE_REVIEW_ALERTS_KEY, {
    leavePreference: null
  })

  const [leaveGroups, setLeaveGroups] = useState<ILeaveGroupWithTypes[]>([])
  const [leaveTypes, setLeaveTypes] = useState<ILeaveTypeInfo[]>([])
  const [selectedLeaveGroupName, setSelectedLeaveGroupName] =
    useState<string>(null)
  const [selectedLeaveType, setSelectedLeaveType] =
    useState<ILeaveTypeInfo>(null)
  const [tpaLeave, setTpaLeave] = useState<ILeave>(null)
  const [selectedDueDate, setSelectedDueDate] = useState(null)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [selectedReinstatementDate, setSelectedReinstatementDate] =
    useState(null)
  const [isFetchingLeave, setIsFetchingLeave] = useState(true)
  const [isCreatingLeave, setIsCreatingLeave] = useState(false)
  const [user, setUser] = useState(null)
  const [noLeave, setNoLeave] = useState(false)
  const [tpaApprovalState, setTpaApprovalState] =
    useState<TpaApprovalState>('none')
  const [currentStage, setCurrentStage] = useState<CreateLeaveStage>(null)
  const [subtype, setSubtype] = useState<string>(null)
  const [leaveStatus, setLeaveStatus] = useState<string>(null)
  const [workSchedule, setWorkSchedule] = useState<string>(null)
  const [timelinePreference, setTimelinePreference] = useState<string>(null)
  const [weekOneWorkDays, setWeekOneWorkDays] = useState<Set<string>>(new Set())
  const [weekTwoWorkDays, setWeekTwoWorkDays] = useState<Set<string>>(new Set())
  const [workScheduleStartDate, setWorkScheduleStartDate] =
    useState<Moment>(null)
  const [countryState, setCountryState] = useState<string>(null)
  const countryCode = useMemo(() => getCountryCode(user), [user])
  const workDays = useMemo(
    () => [weekOneWorkDays, weekTwoWorkDays],
    [weekOneWorkDays, weekTwoWorkDays]
  )
  const [injuryState, setInjuryState] =
    useState<MedicalWorkRelatedInjury>('none')
  const [useTpaLeave, setUseTpaLeave] = useState<TpaLeaveState>('none')
  const [isFetching, setIsFetching] = useState(false)
  const [isMoreThanOneChild, setIsMoreThanOneChild] = useState(false)
  const [userIntent, setUserIntent] = useState<UserIntent>('Unknown')

  const customerLeaveCreateConfig: ICustomerLeaveCreateConfig = useMemo(
    () => customerConfig.leave.create,
    [customerConfig]
  )
  const workflow = useMemo(
    () => Methods.getLeaveCreateWorkflow(customerLeaveCreateConfig),
    [customerLeaveCreateConfig]
  )
  const [progress, setProgress] = useState(workflow.progress)

  const schedule = useMemo(
    () =>
      isRoche(customer) && workScheduleStartDate
        ? ({
            start: workScheduleStartDate,
            weekOne: Array.from(weekOneWorkDays),
            weekTwo: Array.from(weekTwoWorkDays)
          } as ISchedule)
        : null,
    [customer, workScheduleStartDate, weekOneWorkDays, weekTwoWorkDays]
  )

  useCreateMetric({ eventType: MetricEventWelcomeScreenImpression }, !noLeave)
  usePageTitle('welcome')

  useEffect(() => {
    fetchMe()
    fetchConfig()
    fetchLeaveBasic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchConfig = useCallback(async () => {
    try {
      setIsFetching(true)
      const config: IConfig = await props.queries.fetchConfig({
        fetchPolicy: 'cache-first'
      })
      const leaveTypeInfos: ILeaveTypeInfo[] = config.leaveTypes
      const lgs: ILeaveGroupWithTypes[] = []

      leaveTypeInfos.forEach((leaveTypeInfo: ILeaveTypeInfo) => {
        Methods.addLeaveTypeInfoToGroup(leaveTypeInfo, lgs)
      })
      setLeaveGroups(lgs)

      if (lgs.length === 1) {
        const group = lgs[0]
        setSelectedLeaveGroupName(group.name)
        setLeaveTypes(group.leaveTypes)
        setSelectedLeaveType(null)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setIsFetching(false)
    }
  }, [props.queries, navigate])

  const fetchMe = async () => {
    try {
      const userResult: IUser = await props.queries.fetchMe()
      setUser(userResult)
      setCountryState(userResult.countryState)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const fetchStates = async () => {
    try {
      return props.queries.fetchStates()
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const onLeaveResult = useCallback(
    (leaveResult: ILeave) => {
      Methods.onLeaveResult({
        leaveResult,
        navigate,
        toastManager: props.toastManager,
        t,
        setIsFetchingLeave,
        customer,
        useTpaLeave: useTpaLeave === 'yes'
      })
    },
    [props, navigate, t, setIsFetchingLeave, customer, useTpaLeave]
  )

  const fetchTpaLeave = useCallback(async () => {
    try {
      setIsFetching(true)
      const leaveResult: ILeave = await props.queries.fetchTpaLeave(
        selectedLeaveType?.type,
        selectedDueDate,
        selectedStartDate,
        selectedEndDate,
        { fetchPolicy: 'network-only' }
      )
      return leaveResult
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setIsFetching(false)
    }
  }, [
    props.queries,
    selectedLeaveType?.type,
    selectedDueDate,
    selectedStartDate,
    selectedEndDate,
    navigate
  ])

  const fetchLeaveBasic = useCallback(async () => {
    try {
      const leaveResult: ILeave = await props.queries.fetchLeaveBasic({
        fetchPolicy: 'network-only',
        notFound: async () => {
          setNoLeave(true)
        }
      })
      onLeaveResult(leaveResult)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }, [props, onLeaveResult, setNoLeave, navigate])

  const onLeaveCreateError = useCallback(() => {
    navigate(errorPageRoute, {
      state: { error: '' }
    })
  }, [navigate])

  const onLeaveCreated = useCallback(
    async (leaveResult: ILeave) => {
      if (!leaveResult) {
        onLeaveCreateError()
        return
      }
      if (customerConfig.leave.timeline.showReviewAlerts) {
        setShowReviewAlert({ timelinePreference })
      }
      // fetch leave before leaving the page to have "leave" used in navigation
      await fetchLeaveBasic()
    },
    [
      onLeaveCreateError,
      fetchLeaveBasic,
      customerConfig,
      timelinePreference,
      setShowReviewAlert
    ]
  )

  const createLeaveOptions = useMemo(
    () => ({
      notFound: onLeaveCreateError,
      badRequest: onLeaveCreateError,
      unhandled: onLeaveCreateError
    }),
    [onLeaveCreateError]
  )

  const createLeave = useCallback(async () => {
    try {
      const extra: IExtraCreateInput = {}
      if (countryState) {
        extra.countryState = countryState
      }

      if (timelinePreference) {
        extra.timelinePreference = capitalize(
          timelinePreference
        ) as ITimelinePreference
      }

      if (schedule) {
        extra.schedule = schedule
      }

      if (useTpaLeave === 'no') {
        extra.tpaSyncing = false
      }

      const leaveResult = await Methods.createLeaveMutation(
        props.queries,
        createLeaveOptions,
        {
          customerLeaveCreateConfig,
          extra,
          injuryState,
          isMoreThanOneChild,
          leaveStatus,
          onLeaveCreateError,
          selectedDueDate,
          selectedEndDate,
          selectedLeaveGroupName,
          selectedLeaveType,
          selectedReinstatementDate,
          selectedStartDate,
          subtype,
          tpaApprovalState,
          userIntent
        }
      )

      await onLeaveCreated(leaveResult)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }, [
    navigate,
    onLeaveCreateError,
    onLeaveCreated,
    props,
    schedule,
    countryState,
    createLeaveOptions,
    timelinePreference,
    selectedDueDate,
    selectedEndDate,
    selectedLeaveGroupName,
    selectedLeaveType,
    selectedStartDate,
    tpaApprovalState,
    useTpaLeave,
    subtype,
    leaveStatus,
    selectedReinstatementDate,
    customerLeaveCreateConfig,
    injuryState,
    isMoreThanOneChild,
    userIntent
  ])

  const createLeaveFromTpa = useCallback(async () => {
    try {
      const extra: IExtraCreateInput = { tpaSyncing: true }
      if (countryState) {
        extra.countryState = countryState
      }
      const leaveResult = await Methods.createFromTpaLeaveMutation(
        props.queries,
        createLeaveOptions,
        {
          extra,
          selectedLeaveType,
          selectedDueDate,
          selectedStartDate,
          selectedEndDate,
          onLeaveCreateError
        }
      )
      await onLeaveCreated(leaveResult)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }, [
    createLeaveOptions,
    navigate,
    onLeaveCreateError,
    onLeaveCreated,
    props.queries,
    selectedDueDate,
    selectedEndDate,
    selectedLeaveType,
    selectedStartDate,
    countryState
  ])

  const onWorkDayClicked = useCallback(
    (weekNumber: number, day: string) => {
      const days = workDays[weekNumber]
      const newDays: Set<string> = new Set(days)

      if (newDays.has(day)) {
        newDays.delete(day)
      } else {
        newDays.add(day)
      }

      return weekNumber === 0
        ? setWeekOneWorkDays(newDays)
        : setWeekTwoWorkDays(newDays)
    },
    [workDays, setWeekOneWorkDays, setWeekTwoWorkDays]
  )

  const reset = useCallback(() => {
    setSelectedLeaveGroupName(null)
    setLeaveTypes([])
    setSelectedLeaveType(null)
    setSelectedDueDate(null)
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setSubtype(null)
    setLeaveStatus(null)
    setTpaApprovalState('none')
    setInjuryState('none')
    setIsMoreThanOneChild(false)
  }, [])

  const resetWorkSchedule = useCallback(() => {
    setWeekOneWorkDays(new Set<string>())
    setWeekTwoWorkDays(new Set<string>())
    setWorkScheduleStartDate(null)
  }, [setWeekOneWorkDays, setWeekTwoWorkDays])

  const onCreateLeave = useCallback(() => {
    setIsCreatingLeave(true)
    if (useTpaLeave === 'yes') {
      createLeaveFromTpa()
    } else {
      createLeave()
    }
  }, [createLeave, createLeaveFromTpa, useTpaLeave])

  const nextCreateLeaveStage = useCallback(async () => {
    // TODO: should be changed to a more correct solution, giving the possibility to change the workflow based on the changed parameter
    if (customerLeaveCreateConfig.hasTpaFlow(workflow)) {
      await fetchTpaLeave().then(leave => {
        setTpaLeave(leave)
        workflow.setContext({ ...workflow.context, tpaLeave: leave })
      })
    }

    const newStage = workflow.moveNext()

    if (newStage) {
      setCurrentStage(newStage)
    } else {
      onCreateLeave()
    }
  }, [customerLeaveCreateConfig, workflow, fetchTpaLeave, onCreateLeave])

  const prevCreateLeaveStage = useCallback(() => {
    setUseTpaLeave('none')

    const newStage = workflow.moveBack()
    if (newStage) {
      setCurrentStage(newStage)
    }
    if (newStage === 'pickGroup') {
      reset()
    }
  }, [workflow, reset])

  const onTypeChanged = useCallback(
    (leaveType: ILeaveTypeInfo) => {
      Methods.processEligibilityStatus(leaveType, t, navigate)
      setSelectedLeaveType(leaveType)
    },
    [t, navigate, setSelectedLeaveType]
  )

  const onLeaveGroupSelected = useCallback(
    (groupName: string) => {
      const group: ILeaveGroupWithTypes =
        groupName && Methods.getLeaveGroupWithName(groupName, leaveGroups)

      setSelectedLeaveGroupName(groupName)
      setLeaveTypes(group.leaveTypes)
      setSelectedLeaveType(null)
      if (group.leaveTypes.length === 1) {
        onTypeChanged(group.leaveTypes[0])
      }
    },
    [onTypeChanged, setSelectedLeaveGroupName, leaveGroups]
  )

  const limits: any = useMemo(
    () => selectedLeaveType?.limits || {},
    [selectedLeaveType]
  )

  const minEndDate: Moment = useMemo(() => {
    if (!selectedStartDate) {
      return null
    }
    if ('minDuration' in limits && limits.minDuration > 0) {
      return selectedStartDate.clone().add(limits.minDuration - 1, 'days')
    }
    if ('workdaysMinDuration' in limits && limits.workdaysMinDuration > 0) {
      return getAdjustedDateByWorkingDays(
        selectedStartDate,
        limits.workdaysMinDuration - 1
      )
    }
    if ('businessMinDuration' in limits && limits.businessMinDuration > 0) {
      return getAdjustedDateByWorkingDays(
        selectedStartDate,
        limits.businessMinDuration - 1
      )
    }
  }, [selectedStartDate, limits])

  const maxEndDate: Moment = useMemo(() => {
    if (!selectedStartDate) {
      return moment()
    }
    if ('maxDuration' in limits && limits.maxDuration > 0) {
      return selectedStartDate.clone().add(limits.maxDuration - 1, 'days')
    }
    return selectedStartDate.clone().add(100, 'years')
  }, [selectedStartDate, limits])

  const minDate = useMemo(() => {
    if (limits.minStartDate) {
      return moment(limits.minStartDate).utc()
    }
    return moment(0)
  }, [limits])
  const maxDate = useMemo(() => {
    if (limits.maxStartDate) {
      return moment(limits.maxStartDate).utc()
    }
    return moment().add(100, 'years')
  }, [limits])

  const onDueDateChanged = useCallback(
    (date: Moment) => {
      setSelectedDueDate(date)
    },
    [setSelectedDueDate]
  )

  const onStartDateChanged = useCallback(
    (date: Moment) => {
      setSelectedStartDate(date)
      if (!selectedEndDate) {
        return
      }
      if ('minDuration' in limits && limits.minDuration > 0) {
        if (selectedEndDate.diff(date, 'days') < limits.minDuration - 1) {
          setSelectedEndDate(null)
        }
      }
      if ('workdaysMinDuration' in limits && limits.workdaysMinDuration > 0) {
        if (
          getAdjustedDateByWorkingDays(
            date,
            limits.workdaysMinDuration.value - 1
          ).isAfter(selectedEndDate)
        ) {
          setSelectedEndDate(null)
        }
      }
      if ('businessMinDuration' in limits && limits.businessMinDuration > 0) {
        if (
          getAdjustedDateByWorkingDays(
            date,
            limits.businessMinDuration.value - 1
          ).isAfter(selectedEndDate)
        ) {
          setSelectedEndDate(null)
        }
      }
    },
    [setSelectedStartDate, selectedEndDate, setSelectedEndDate, limits]
  )

  const onEndDateChanged = useCallback(
    (date: Moment) => {
      setSelectedEndDate(date)
    },
    [setSelectedEndDate]
  )

  const onReinstatementDateChanged = useCallback(
    (date: Moment) => {
      setSelectedReinstatementDate(date)
    },
    [setSelectedReinstatementDate]
  )

  const isEndDateLaterThanEndDate = useCallback(
    (value: number, duration: string) => {
      if (!selectedStartDate || !selectedEndDate) {
        return false
      }

      return selectedEndDate.diff(selectedStartDate, duration) > value
    },
    [selectedStartDate, selectedEndDate]
  )

  const showsMoreThanTenWeeksMedicalNote: boolean = useMemo(
    () => isEndDateLaterThanEndDate(10, 'weeks'),
    [isEndDateLaterThanEndDate]
  )

  const showsMoreThanSixMonthsMilitaryNote: boolean = useMemo(
    () => isEndDateLaterThanEndDate(5, 'months'),
    [isEndDateLaterThanEndDate]
  )

  const onTpaApprovalStateChange = useCallback(
    (value: TpaApprovalState) => {
      setTpaApprovalState(value)
    },
    [setTpaApprovalState]
  )

  const militaryDaysCount: number = useMemo(() => {
    if (
      selectedLeaveGroupName !== 'Military' ||
      !selectedStartDate ||
      !selectedEndDate
    ) {
      return -1
    }

    return selectedEndDate.diff(selectedStartDate, 'days') + 1
  }, [selectedLeaveGroupName, selectedStartDate, selectedEndDate])

  const isMilitaryIncludingReturn: boolean = useMemo(
    () => militaryDaysCount > 30,
    [militaryDaysCount]
  )

  const reinstatementMaxDurations: any[] = useMemo(
    () =>
      limits.reinstatementMaxDurations?.length
        ? limits.reinstatementMaxDurations
        : [],
    [limits]
  )

  const highlightedDateNow: Moment = useMemo(
    () => (countryCode === 'IN' ? moment().utc() : null),
    [countryCode]
  )

  const contextValue: ICreateLeaveContext = {
    countryCode,
    countryState,
    customer,
    customerLeaveCreateConfig,
    fetchStates,
    highlightedDateNow,
    injuryState,
    isCreatingLeave,
    isFetchingLeave,
    isMilitaryIncludingReturn,
    isMoreThanOneChild,
    leaveGroups,
    leaveStatus,
    leaveTypes,
    maxDate,
    maxEndDate,
    militaryDaysCount,
    minDate,
    minEndDate,
    nextCreateLeaveStage,
    onDueDateChanged,
    onEndDateChanged,
    onLeaveGroupSelected,
    onReinstatementDateChanged,
    onStartDateChanged,
    onTpaApprovalStateChange,
    onTypeChanged,
    onWorkDayClicked,
    prevCreateLeaveStage,
    reinstatementMaxDurations,
    resetWorkSchedule,
    selectedDueDate,
    selectedEndDate,
    selectedLeaveGroupName,
    selectedLeaveType,
    selectedReinstatementDate,
    selectedStartDate,
    setCountryState,
    setInjuryState,
    setIsMoreThanOneChild,
    setLeaveStatus,
    setSubtype,
    setTimelinePreference,
    setUseTpaLeave,
    setWorkSchedule,
    setWorkScheduleStartDate,
    showsMoreThanSixMonthsMilitaryNote,
    showsMoreThanTenWeeksMedicalNote,
    subtype,
    timelinePreference,
    tpaApprovalState,
    tpaLeave,
    userIntent,
    setUserIntent,
    user,
    useTpaLeave,
    workDays,
    workSchedule,
    workScheduleStartDate
  }

  const currentContent = useMemo(
    () => Methods.getCurrentStageView(currentStage, customerConfig),
    [currentStage, customerConfig]
  )

  useEffect(() => {
    workflow.setContext(contextValue)

    if (workflow.current === 'pickGroup' && selectedLeaveGroupName) {
      nextCreateLeaveStage()
    }

    if (currentStage !== workflow.current) {
      setCurrentStage(workflow.current)
    }

    if (!isEqual(progress, workflow.progress)) {
      setProgress(workflow.progress)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue])

  if (!customer || isFetching) {
    return <LoadingSpinner fullScreen fadesIn />
  }

  return (
    <Page>
      <ProgressBar current={progress.current} total={progress.total} />
      <LeaveContext.Provider value={contextValue}>
        {currentContent}
      </LeaveContext.Provider>
    </Page>
  )
})

CreateLeaveContainer.displayName = 'CreateLeaveContainer'

export default withToastManager(withQueries(CreateLeaveContainer))
