import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import zIndex from 'src/constants/zIndex'
import { Button, H1 } from 'src/UIKit'
import TitleMobile from 'src/features/UserProfile/components/CloseOutPlanOverlay/components/TitleMobile'
import RadioButtonOption from 'src/components/RadioButtonOption'
import CreateLeaveNote from 'src/features/Leave/components/UI/CreateLeaveNote'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'

type CloseOutPlanBehaviour = 'justCloseOut' | 'askNotifyManager'

interface IProps {
  onBack: () => void
  behaviour: CloseOutPlanBehaviour
  onCloseOutPlan: (
    shouldAddNotifyManagerField: boolean,
    notifyManager: boolean
  ) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.userProfile.closeOutPlanPlanOverlay};
  background: ${props => props.theme.colors.backgroundColor};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin: 0 16px;
    `}
  ${mobileMaxWidthMixin};
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 100%;
          max-width: 736px;
        `
      : css`
          width: calc(100% - 32px);
          padding: 28px 16px 16px;
        `}
`

const Title = styled(H1)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
        `
      : css`
          margin-top: 0;
        `}
`

const Description = styled.p`
  line-height: 150%;
  white-space: pre-line;
  color: ${props => props.theme.colors.dark60};
  width: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
          margin-top: 42px;
        `}

  ${mobileMaxWidthMixin};
`

const OptionContainer = styled.ul`
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;
  overflow: hidden;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
          width: 640px;
          max-width: 640px;
        `
      : css`
          margin-top: 16px;
          width: 100%;
        `}
`

const optionTextMixin = css`
  font-weight: normal;
  color: ${(p: any) => p.theme.colors.dark60};
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          justify-content: center;
          margin: 32px 0 48px;
        `
      : css`
          flex: 1;
          align-items: flex-end;
          width: calc(100% - 32px);
          padding: 0 16px 16px;
        `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;

          &:not(:first-child) {
            margin-left: 16px;
          }
        `
      : css`
          width: 100%;
          margin-top: 24px;

          &:first-child {
            margin: 24px auto 0;
          }

          &:not(:first-child) {
            margin-top: 16px;
          }
        `}

  ${mobileButtonMaxWidthMixin};
`

const NoteWrapper = styled(CreateLeaveNote)<{ $shows: boolean }>`
  ${props =>
    props.$shows &&
    css`
      margin: ${props.theme.isDesktop ? '40px' : '24px'} 0 0;
    `}
`

type ShareOption = 'yes' | 'no' | 'notSet'

export const CloseOutPlanOverlay = React.memo((props: IProps) => {
  const { onBack, onCloseOutPlan, behaviour } = props
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState<ShareOption>('notSet')

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === 27) {
        onBack()
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onBack])

  const titleText: string = t('closePlan.overlay.title')

  const selectOption = useCallback(
    (option: ShareOption) => {
      setSelectedOption(option)
    },
    [setSelectedOption]
  )

  const options: ReactNode[] = useMemo(() => {
    if (behaviour === 'justCloseOut') {
      return null
    }

    return [
      <RadioButtonOption
        key={'1'}
        selected={selectedOption === 'yes'}
        onClick={() => {
          selectOption('yes')
        }}
        optionTextMixin={optionTextMixin}
      >
        {t('closePlan.overlay.optionNotifyYes')}
      </RadioButtonOption>,
      <RadioButtonOption
        key={'2'}
        selected={selectedOption === 'no'}
        onClick={() => {
          selectOption('no')
        }}
        optionTextMixin={optionTextMixin}
        hidesSeparator
      >
        {t('closePlan.overlay.optionNotifyNo')}
      </RadioButtonOption>
    ]
  }, [behaviour, selectedOption, selectOption, t])

  const notifyYourManagerNote: ReactNode = useMemo(
    () => (
      <NoteWrapper
        shows={selectedOption === 'no' || behaviour === 'justCloseOut'}
        $shows={selectedOption === 'no' || behaviour === 'justCloseOut'}
        text={t('closePlan.overlay.notifyManagerNote')}
      />
    ),
    [t, selectedOption, behaviour]
  )

  const isActiveButtonDisabled: boolean = useMemo(
    () => behaviour === 'askNotifyManager' && selectedOption === 'notSet',
    [behaviour, selectedOption]
  )

  const { isDesktop } = useContext(ScreenContext)

  return (
    <React.Fragment>
      {!isDesktop && (
        <TitleMobile onArrowClick={onBack} role={'alert'}>
          {titleText}
        </TitleMobile>
      )}
      <Container>
        <InnerContainer>
          {isDesktop && <Title role={'alert'}>{titleText}</Title>}
          <Description role={'dialog'}>
            {t('closePlan.overlay.description', { context: behaviour })}
          </Description>
          {options && (
            <OptionContainer role="radiogroup">{options}</OptionContainer>
          )}
          {notifyYourManagerNote}
        </InnerContainer>
        <ButtonsContainer>
          {isDesktop && (
            <ButtonWrapper appearance={'cancel'} onClick={onBack}>
              {t('common.back')}
            </ButtonWrapper>
          )}
          <ButtonWrapper
            appearance="destructive"
            disabled={isActiveButtonDisabled}
            onClick={() => {
              onCloseOutPlan(true, selectedOption === 'yes')
            }}
            ariaLabel={
              isActiveButtonDisabled
                ? t('common.accessibilityText.selectOptionToCloseOutPlan')
                : null
            }
          >
            {t('closePlan.button')}
          </ButtonWrapper>
        </ButtonsContainer>
      </Container>
    </React.Fragment>
  )
})

CloseOutPlanOverlay.displayName = 'CloseOutPlanOverlay'

export default CloseOutPlanOverlay
