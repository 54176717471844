import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from 'react'
import styled, { css } from 'styled-components'
import { Checkbox, UIText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  className?: any
  notificationSettings?: INotificationSetting[]
  updateNotificationSettings: (
    notificationSettings: INotificationSetting[]
  ) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const DescriptionWrapper = styled(UIText)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 736px;
          margin: 0 0 40px;
        `
      : css`
          margin: 0 0 8px;
        `}
`

const Table = styled.table`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.dark10};
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  color: ${props => props.theme.colors.dark80};
`

const Tr = styled.tr`
  &:last-child th:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child th:last-child {
    border-top-right-radius: 8px;
  }

  &:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  &:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`

const Th = styled.th<{ $textAlign?: string }>`
  height: ${props => (props.theme.isDesktop ? '53px' : '39px')};
  background: ${props => props.theme.colors.dark05};
  font-weight: 500;
  color: ${props => props.theme.colors.dark60};
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  text-align: ${props => props.$textAlign || 'center'};
  vertical-align: middle;

  &:first-child {
    padding-left: ${props => (props.theme.isDesktop ? '16px' : '12px')};
  }
`

const Td = styled.td<{ $textAlign?: string }>`
  height: ${props => (props.theme.isDesktop ? '56px' : '44px')};
  border: 1px solid ${props => props.theme.colors.dark10};
  color: ${props => props.theme.colors.dark80};
  text-align: ${props => props.$textAlign || 'center'};
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  vertical-align: middle;
  border-left: none;
  border-right: none;
  border-bottom: none;

  &:first-child {
    padding-left: ${props => (props.theme.isDesktop ? '16px' : '12px')};
  }
`

const CheckboxWrapper = styled(Checkbox)`
  display: inline-block;
`

const NotificationsSettings = React.memo((props: IProps) => {
  const { updateNotificationSettings, className } = props
  const { i18Context } = useContext(ScreenContext)
  const { t } = useTranslation()
  const [notificationSettings, setNotificationSettings] = useState(null)

  useEffect(() => {
    setNotificationSettings(props.notificationSettings)
  }, [props.notificationSettings])

  const onCheckboxChange = useCallback(
    (value: boolean, payload: any) => {
      const [currentType, currentChannelKey] = payload.split('_')

      const requestPayload: any[] = []
      const newNotificationsState: INotificationSetting[] = []

      for (const notificationSetting of notificationSettings) {
        const setting: INotificationSetting = notificationSetting
        const { type, channels } = setting
        const stateChannels: any = { ...channels }
        const channelsPayload: any = {}
        Object.keys(channels).forEach((key: string) => {
          channelsPayload[key] = (channels as any)[key].selected
        })
        if (type === currentType) {
          stateChannels[currentChannelKey] = {
            ...stateChannels[currentChannelKey],
            selected: value
          }
          channelsPayload[currentChannelKey] = value
        }
        newNotificationsState.push({ type, channels: stateChannels })
        requestPayload.push({ type, channels: channelsPayload })
      }

      setNotificationSettings(newNotificationsState)
      updateNotificationSettings(requestPayload)
    },
    [notificationSettings, updateNotificationSettings]
  )

  const getCheckboxView = useCallback(
    (type: IPriority, channelKey: string, channels: any) => {
      const { selected, enabled } = channels[channelKey]
      const payload = `${type}_${channelKey}`
      const ariaLabel = [
        t(
          `common.accessibilityText.notificationCheckboxAreaLabel.priority.${type}`
        ),
        t(
          `common.accessibilityText.notificationCheckboxAreaLabel.channel.${channelKey}`
        )
      ].join(', ')

      return (
        <CheckboxWrapper
          key={payload}
          name={'checkbox'}
          ariaLabel={ariaLabel}
          checked={selected}
          disabled={!enabled}
          payload={payload}
          onChange={onCheckboxChange}
        />
      )
    },
    [onCheckboxChange, t]
  )

  const rows = useMemo(
    () =>
      notificationSettings?.map(
        (setting: INotificationSetting, index: number) => {
          const { type, channels } = setting
          return (
            <Tr key={index}>
              <Td $textAlign="left">{t(`notificationSettings.${type}`)}</Td>
              <Td>{getCheckboxView(type, 'corporateEmail', channels)}</Td>
              <Td>{getCheckboxView(type, 'personalEmail', channels)}</Td>
              <Td>{getCheckboxView(type, 'phoneNumber', channels)}</Td>
            </Tr>
          )
        }
      ),
    [notificationSettings, t, getCheckboxView]
  )

  return (
    <Container className={className}>
      <DescriptionWrapper allowsParagraph>
        {t('profileAndSettings.notificationsSettings.description')}
      </DescriptionWrapper>
      <Table>
        <colgroup>
          <col width="40%" />
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
        </colgroup>
        <thead>
          <Tr>
            <Th $textAlign="left">
              {t('profileAndSettings.notificationsSettings.subject')}
            </Th>
            <Th>
              {t('profileAndSettings.notificationsSettings.corporateEmail', {
                context: i18Context
              })}
            </Th>
            <Th>
              {t('profileAndSettings.notificationsSettings.personalEmail', {
                context: i18Context
              })}
            </Th>
            <Th>{t('profileAndSettings.notificationsSettings.text')}</Th>
          </Tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Container>
  )
})

NotificationsSettings.displayName = 'NotificationsSettings'

export default NotificationsSettings
