import * as Sentry from '@sentry/react'

const ALLOWED_URLS = [/^https:\/\/.*\.getveer\.com/]
const IGNORE_ERRORS = [
  /^Failed to register a ServiceWorker for scope/,
  /^Non-Error promise rejection captured/
]

export const beforeSendCleanup = (event: any) => {
  const whiteList = ['id', 'countryCode', 'countryState', 'timezone']

  if (event.user) {
    event.user = filterAttributes(event.user, whiteList)
  }
  if (event?.contexts?.user) {
    event.contexts.user = filterAttributes(event.contexts.user, whiteList)
  }
  if (event?.extra?.user) {
    event.extra.user = filterAttributes(event.extra.user, whiteList)
  }

  return event
}

const filterAttributes = (obj: any, whiteList: string[]) => {
  const newObj: any = {}
  for (const key in obj) {
    if (whiteList.includes(key)) {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

export const captureResponseError = (response: any) => {
  Sentry.captureMessage(`Response error: ${response.url}`, {
    extra: {
      status: response.status,
      statusText: response.statusText,
      route: window.location.href
    }
  })
}

export const captureException = (error: any) => {
  Sentry.captureException(error)
}

export const initSentry = () => {
  const config = window.APP_CONFIG

  if (!config?.SENTRY.DSN) {
    console.warn('No Sentry DSN provided, skipping Sentry initialization')
    return
  }

  Sentry.init({
    dsn: config.SENTRY.DSN,
    release: config.SENTRY.TAG,
    environment: config.SENTRY.ENV,
    integrations: [Sentry.browserTracingIntegration()],
    beforeSend: event => beforeSendCleanup(event),
    allowUrls: ALLOWED_URLS,
    ignoreErrors: IGNORE_ERRORS,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
