import styled from 'styled-components'

const Value = styled.div`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  font-weight: 400;
  color: ${props => props.theme.colors.dark80};
  text-align: left;
  line-height: 150%;
  padding-top: 2px;
`

export default Value
