const createSize =
  getSizeFunc =>
  (...args) => {
    const value = getSizeFunc(...args)
    return {
      px: `${value}px`,
      value
    }
  }

const header = {
  height: createSize(theme => (theme.isDesktop ? 64 : 48))
}

const footer = {
  height: createSize(() => 40)
}

const createLeaveButtons = {
  width: createSize(() => 512)
}

const pageButtons = {
  minWidth: createSize(() => 256)
}

const journeyMapItem = {
  mediaMaxWidth: createSize(() => 300)
}

const timeline = {
  paddingTopDefault: createSize(() => 24)
}

const scrollOffset = {
  top: createSize(theme => (theme.isDesktop ? 108 : 64))
}

const tooltip = {
  offset: (): [number, number] => [45, 15]
}

const timelineNotice = {
  widthOffset: createSize(theme => (theme.isDesktop ? 326 : 48))
}

const banner = {
  height: createSize(theme => (theme.isDesktop ? 58 : 56))
}

const popoverOffset = {
  top: createSize(() => 14)
}

export {
  header,
  footer,
  createLeaveButtons,
  journeyMapItem,
  timeline,
  scrollOffset,
  tooltip,
  timelineNotice,
  banner,
  popoverOffset,
  pageButtons
}
