import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Welcome from 'src/features/Welcome'
import CreateLeave from 'src/features/Leave/CreateLeaveContainer'
import LoginErrorPage from 'src/components/pages/LoginErrorPage'
import Timeline from 'src/features/Timeline/TimelineContainer'
import JourneyMap from 'src/features/JourneyMap/containers/JourneyMapContainer'
import UserProfile from 'src/features/UserProfile/UserProfileContainer'
import Notifications from 'src/features/Notifications/NotificationsPageContainer'
import HrAdmin from 'src/features/HrAdmin/HrAdminContainer'
import HelpCenter from 'src/features/HelpCenter/HelpCenterContainer'
import PlanChange from 'src/features/PlanChange/PlanChangeContainer'
import ClaimUpdateContainer from 'src/features/ClaimUpdate/ClaimUpdateContainer'
import PageWrapper from 'src/components/PageWrapper'
import ErrorPage from 'src/components/pages/ErrorPage'
import UnsupportedPage from 'src/components/pages/UnsupportedPage'
import NotFoundPage from 'src/components/pages/NotFoundPage'
import LoggedOutPage from 'src/components/pages/LoggedOutPage'
import UpdateAppPromptView from 'src/components/UpdateAppPromptView'
import EligibilityNoticePage from 'src/components/pages/EligibilityNoticePage'
import GetRemindersPage from 'src/components/pages/GetRemindersPage'
import ThanksPage from 'src/components/pages/ThanksPage'
import PlanPage from 'src/components/pages/PlanPage'
import UserConsentPage from 'src/components/pages/UserConsentPage'
import CloseAccountPage from 'src/components/pages/CloseAccountPage'
import * as Constants from './constants'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import WithRouteContextWrapper from 'src/routes/withRouteContext'
import ManagerDashboardActiveContainer from 'src/features/ManagerJourneyMap/containers/DashboardActiveContainer'
import DashboardPreviousVersionsContainer from 'src/features/ManagerJourneyMap/containers/DashboardPreviousVersionsContainer'
import ManagerDashboardArchivedContainer from 'src/features/ManagerJourneyMap/containers/DashboardArchivedContainer'
import ManagerJourneyMapContainer from 'src/features/ManagerJourneyMap/containers/ManagerJourneyMapContainer'
import AbsenceRedirectHandler from 'src/features/ManagerJourneyMap/containers/AbsenceRedirectHandler'
import ManagerHelpCenter from 'src/features/ManagerHelpCenter/HelpCenterContainer'
import TransitionStepsContainer from 'src/features/TransitionFlow/TransitionFlowContainer'
import PregnancyChangeContainer from 'src/features/Timeline/components/common/PregnancyChangeContainer'
import InfoPage from 'src/components/pages/InfoPage'

interface IProps {
  client: ApolloClient<NormalizedCacheObject>
  setPathname: (p: string) => void
  setIsLoading: (l: boolean) => void
  setCustomer: (c: string) => void
}

const AllRoutes = (props: IProps) => (
  <BrowserRouter>
    <UpdateAppPromptView />
    <WithRouteContextWrapper>
      <PageWrapper {...props}>
        <Routes>
          <Route path={Constants.welcomeRoute} element={<Welcome />} />
          <Route
            path={Constants.loginErrorPageRoute}
            element={<LoginErrorPage />}
          />
          <Route path={Constants.createLeaveRoute} element={<CreateLeave />} />
          <Route path={Constants.timelineRoute} element={<Timeline />} />
          <Route path={Constants.journeyMapRoute} element={<JourneyMap />} />
          <Route
            path={Constants.managerPreviousVersionsRoute}
            element={<DashboardPreviousVersionsContainer />}
          />
          <Route
            path={Constants.managerDashboardRoute}
            element={<ManagerDashboardActiveContainer />}
          />
          <Route
            path={Constants.managerArchiveRoute}
            element={<ManagerDashboardArchivedContainer />}
          />
          <Route
            path={Constants.managerDashboardJourneyMapRoute}
            element={<ManagerJourneyMapContainer isArchive={false} />}
          />
          <Route
            path={Constants.managerArchiveJourneyMapRoute}
            element={<ManagerJourneyMapContainer isArchive />}
          />
          <Route
            path={Constants.managerJourneyMapRoute}
            element={<AbsenceRedirectHandler />}
          />
          <Route
            path={Constants.managerHelpCenterRoute}
            element={<ManagerHelpCenter />}
          />
          <Route path={Constants.userProfileRoute} element={<UserProfile />} />
          <Route
            path={Constants.notificationsRoute}
            element={<Notifications />}
          />
          <Route
            path={Constants.managerNotificationsRoute}
            element={<Notifications />}
          />
          <Route path={Constants.helpCenterRoute} element={<HelpCenter />} />
          <Route path={Constants.planChangeRoute} element={<PlanChange />} />
          <Route
            path={Constants.claimUpdateRoute}
            element={<ClaimUpdateContainer />}
          />
          <Route path={Constants.hrAdminDashboardRoute} element={<HrAdmin />} />
          <Route path={Constants.errorPageRoute} element={<ErrorPage />} />
          <Route
            path={Constants.unsupportedPageRoute}
            element={<UnsupportedPage />}
          />
          <Route
            path={Constants.unsupportedPageRoute + '/:hash'}
            element={<UnsupportedPage />}
          />
          <Route
            path={Constants.loggedOutPageRoute}
            element={<LoggedOutPage />}
          />
          <Route
            path={Constants.eligibilityNoticePageRoute}
            element={<EligibilityNoticePage />}
          />
          <Route
            path={Constants.getRemindersPageRoute}
            element={<GetRemindersPage />}
          />
          <Route path={Constants.thanksPageRoute} element={<ThanksPage />} />
          <Route path={Constants.outdatedPlanRoute} element={<PlanPage />} />
          <Route path={Constants.completedPlanRoute} element={<PlanPage />} />
          <Route
            path={Constants.userConsentRoute}
            element={<UserConsentPage />}
          />
          <Route
            path={Constants.closeAccountRoute}
            element={<CloseAccountPage />}
          />
          <Route
            path={Constants.transitionFlowRoute}
            element={<TransitionStepsContainer />}
          />
          <Route
            path={Constants.somethingHasChangedRoute}
            element={<PregnancyChangeContainer />}
          />
          <Route path={Constants.infoPageRoute} element={<InfoPage />} />
          <Route path={Constants.globalSurveysRoute} element={<JourneyMap />} />
          <Route
            path={Constants.journeyMapItemRoute}
            element={<JourneyMap />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PageWrapper>
    </WithRouteContextWrapper>
  </BrowserRouter>
)

AllRoutes.displayName = 'AllRoutes'

export default AllRoutes
