import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import { useTranslation } from 'react-i18next'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import NormalRadioButtonOption from '../NormalRadioButtonOption'
import CreateLeaveNote from '../UI/CreateLeaveNote'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import { getDueDate, getDueDateLabelKey } from 'src/utils/leaveUtils'
import moment from 'moment'

const OptionContainer = styled.ul`
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
          max-width: 640px;
          background: ${props.theme.colors.light100};
        `
      : css`
          width: 100%;
        `}
`

export const SelectTpaLeaveView = React.memo(() => {
  const {
    prevCreateLeaveStage,
    nextCreateLeaveStage,
    useTpaLeave,
    setUseTpaLeave,
    tpaLeave
  } = useContext<ICreateLeaveContext>(Context)
  const { t } = useTranslation()

  const dueDate: string = useMemo(
    () => getDueDate(tpaLeave).current.format(DATE_FORMAT),
    [tpaLeave]
  )

  const firstReceivedAt: string = useMemo(
    () =>
      tpaLeave?.tpaClaimFirstReceivedAt
        ? moment(tpaLeave.tpaClaimFirstReceivedAt).utc().format(DATE_FORMAT)
        : '',
    [tpaLeave]
  )

  const dueDateLabel: string = useMemo(
    () => t(getDueDateLabelKey(tpaLeave)),
    [tpaLeave, t]
  )

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={useTpaLeave === 'yes'}
        onClick={() => {
          setUseTpaLeave('yes')
        }}
      >
        {t('createLeave.selectTpaLeave.yes', {
          claimID: tpaLeave.initialClaimID
        })}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={useTpaLeave === 'no'}
        onClick={() => {
          setUseTpaLeave('no')
        }}
        hidesSeparator
      >
        {t('createLeave.selectTpaLeave.no')}
      </NormalRadioButtonOption>
    ],
    [t, useTpaLeave, setUseTpaLeave, tpaLeave.initialClaimID]
  )

  const content: any = useMemo(
    () => (
      <>
        <OptionContainer>{options}</OptionContainer>
        <CreateLeaveNote
          text={t('createLeave.selectTpaLeave.note', {
            claimID: tpaLeave.initialClaimID
          })}
          shows={useTpaLeave === 'no'}
          marginTop={32}
        />
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={prevCreateLeaveStage}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={useTpaLeave === 'none'}
        />
      </>
    ),
    [
      options,
      t,
      prevCreateLeaveStage,
      nextCreateLeaveStage,
      useTpaLeave,
      tpaLeave.initialClaimID
    ]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>
        {t('createLeave.selectTpaLeave.title')}
      </CreateLeaveTitle>
      <CreateLeaveDescription>
        {t('createLeave.selectTpaLeave.description', {
          dueDate,
          dueDateLabel: dueDateLabel.toLowerCase(),
          claimID: tpaLeave.initialClaimID,
          firstReceivedAt,
          context: tpaLeave.type
        })}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

SelectTpaLeaveView.displayName = 'SelectTpaLeaveView'

export default SelectTpaLeaveView
