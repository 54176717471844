import { gql } from '@apollo/client'
import {
  partsAbsences,
  partsAbsencesDashboard,
  partsAnalyticsChart,
  partsConfiguration,
  partsCountryConfiguration,
  partsConfirmations,
  partsLeaveBasic,
  partsLeaveDates,
  partsLeaveJourneyMap,
  partsLeaveMetadata,
  partsLeaveTpa,
  partsManagerActivationInfo,
  partsLeaveTimeline,
  partsMe,
  partsHolidays
} from 'src/graphql/fragments'

const queryClaimUpdateDetails = gql`
  query claimUpdateDetails {
    claimUpdateDetails {
      skipWelcomePage
      currentTimeline {
        type
        value
      }
      futureDatedPeriods {
        itemId
        type
        value
        isSynced
      }
    }
  }
`

const queryConfig = gql`
  query Configuration {
    configuration {
      ...partsConfiguration
    }
  }
  ${partsConfiguration}
`

const queryCountryConfigs = gql`
  query CountryConfigurations {
    countryConfigurations {
      ...partsCountryConfiguration
    }
  }
  ${partsCountryConfiguration}
`

const queryStates = gql`
  query States {
    states
  }
`

const queryMe = gql`
  query Me {
    me {
      ...partsMe
    }
  }
  ${partsMe}
`

const querySessions = gql`
  query Sessions {
    sessions {
      id
      ip
      client
      device
      date
      current
    }
  }
`

const queryConfirmations = gql`
  query Me {
    me {
      ...partsConfirmations
    }
  }
  ${partsConfirmations}
`

const queryHolidays = gql`
  query holidays($start: Time!, $end: Time!) {
    holidays(start: $start, end: $end) {
      ...partsHolidays
    }
  }
  ${partsHolidays}
`

const mutationUpdateUser = gql`
  mutation UpdateUser($changes: UserInput) {
    updateUser(changes: $changes) {
      ...partsMe
    }
  }
  ${partsMe}
`

const mutationUpdateConfirmation = gql`
  mutation UpdateConfirmation($code: String!, $kind: ConfirmationType!) {
    updateConfirmation(code: $code, kind: $kind) {
      type
      validTill
      confirmedAt
    }
  }
`

const mutationResendConfirmation = gql`
  mutation ResendConfirmation($kind: ConfirmationType!) {
    resendConfirmation(kind: $kind) {
      type
      validTill
      confirmedAt
    }
  }
`

const mutationLogout = gql`
  mutation logOut {
    logout
  }
`

const mutationCreateLeaveFromTpa = gql`
  mutation CreateLeaveFromTpa($type: LeaveType!, $changes: CreateLeaveInput) {
    createLeaveFromTpa(type: $type, changes: $changes) {
      type
    }
  }
`

const mutationCreateLeave = gql`
  mutation CreateLeave($type: LeaveType!, $changes: CreateLeaveInput) {
    createLeave(type: $type, changes: $changes) {
      type
      metadata
    }
  }
`

const mutationUpdateLeave = gql`
  mutation UpdateLeave($changes: UpdateLeaveInput!) {
    updateLeave(changes: $changes) {
      ...partsLeaveTimeline
      ...partsLeaveTpa
    }
  }
  ${partsLeaveTimeline}
  ${partsLeaveTpa}
`

const mutationStartLeave = gql`
  mutation startLeave {
    startLeave {
      ...partsLeaveTimeline
    }
  }
  ${partsLeaveTimeline}
`

const mutationAcceptLeaveChanges = gql`
  mutation saveLeaveChanges {
    saveLeaveChanges {
      ...partsLeaveTimeline
      ...partsLeaveTpa
    }
  }
  ${partsLeaveTimeline}
  ${partsLeaveTpa}
`

const mutationCancelLeaveChanges = gql`
  mutation cancelLeaveChanges {
    cancelLeaveChanges {
      ...partsLeaveTimeline
      ...partsLeaveTpa
    }
  }
  ${partsLeaveTimeline}
  ${partsLeaveTpa}
`

const mutationRemoveLeave = gql`
  mutation removeLeave($id: ID!) {
    removeLeave(id: $id)
  }
`

const mutationSuspendLeave = gql`
  mutation suspendLeave($notifyManager: Boolean) {
    suspendLeave(notifyManager: $notifyManager)
  }
`

const mutationArchiveLeave = gql`
  mutation archiveLeave($notifyManager: Boolean) {
    archiveLeave(notifyManager: $notifyManager)
  }
`

const mutationSetBirthDate = gql`
  mutation setBirthForLeave(
    $birthDate: Time!
    $subtype: LeaveSubType
    $disabilityEndDate: Time
  ) {
    setBirthForLeave(
      birthDate: $birthDate
      subtype: $subtype
      disabilityEndDate: $disabilityEndDate
    ) {
      ...partsLeaveTimeline
    }
  }
  ${partsLeaveTimeline}
`

const mutationResetBirthForLeave = gql`
  mutation resetBirthForLeave {
    resetBirthForLeave {
      ...partsLeaveTimeline
    }
  }
  ${partsLeaveTimeline}
`

const mutationResetToMiscarriage = gql`
  mutation resetToMiscarriage(
    $date: Time!
    $extra: ExtraResetToMiscarriageInput
  ) {
    resetToMiscarriage(date: $date, extra: $extra) {
      ...partsLeaveTimeline
    }
  }
  ${partsLeaveTimeline}
`

const mutationToggleJourneyMapItem = gql`
  mutation toggleJourneyMapItem($id: String!) {
    toggleJourneyMapItem(id: $id)
  }
`

const mutationToggleAbsenceJourneyMapItem = gql`
  mutation toggleAbsenceJourneyMapItem($id: String!, $completed: Boolean!) {
    toggleAbsenceJourneyMapItem(id: $id, completed: $completed)
  }
`

const mutationUpdateAbsenceSettings = gql`
  mutation updateAbsenceSettings(
    $id: String!
    $changes: AbsenceSettingsInput!
  ) {
    updateAbsenceSettings(id: $id, changes: $changes)
  }
`

const mutationSetTpaSyncing = gql`
  mutation setTpaSyncing($value: Boolean!) {
    setTpaSyncing(value: $value)
  }
`

const mutationAcceptTpaChanges = gql`
  mutation acceptTpaChanges($clearHistory: Boolean!) {
    acceptTpaChanges(clearHistory: $clearHistory)
  }
`

const mutationArchiveAbsence = gql`
  mutation archiveAbsence($id: ID!, $archived: Boolean!) {
    archiveAbsence(id: $id, archived: $archived)
  }
`

const mutationRemoveAbsence = gql`
  mutation removeAbsence($id: ID!) {
    removeAbsence(id: $id)
  }
`

const mutationReadNotification = gql`
  mutation readNotification($id: String!) {
    readNotification(id: $id)
  }
`

const mutationReadAllNotifications = gql`
  mutation readAllNotifications($type: NotificationType) {
    readAllNotifications(type: $type)
  }
`

const mutationActivateManagerForLeave = gql`
  mutation activateManagerForLeave {
    activateManagerForLeave
  }
`

const mutationCreateAbsenceSurveyQuestionResponse = gql`
  mutation createAbsenceSurveyQuestionResponse(
    $absenceID: String!
    $surveyID: String!
    $response: SurveyResponseInput!
    $surveyResponseID: String
  ) {
    createAbsenceSurveyQuestionResponse(
      absenceID: $absenceID
      surveyID: $surveyID
      response: $response
      surveyResponseID: $surveyResponseID
    )
  }
`

const mutationCreateSurveyQuestionResponse = gql`
  mutation createSurveyQuestionResponse(
    $surveyID: String!
    $response: SurveyResponseInput!
    $surveyResponseID: String
  ) {
    createSurveyQuestionResponse(
      surveyID: $surveyID
      response: $response
      surveyResponseID: $surveyResponseID
    )
  }
`

const mutationRequestTpaUpdate = gql`
  mutation requestTpaUpdate {
    requestTpaUpdate
  }
`

const mutationRevokeSession = gql`
  mutation revokeSession($id: ID!) {
    revokeSession(id: $id)
  }
`

const mutationRevokeAllSessions = gql`
  mutation revokeAllSessions {
    revokeAllSessions
  }
`

const mutationCreateMetric = gql`
  mutation createMetric($id: String, $eventType: String!) {
    createMetric(id: $id, eventType: $eventType)
  }
`

const mutationCompleteTransitionFlow = gql`
  mutation completeTransitionFlow($transitionFlowType: TransitionFlowType!) {
    completeTransitionFlow(transitionFlowType: $transitionFlowType)
  }
`

const mutationCloseAlert = gql`
  mutation closeAlert($id: String) {
    closeAlert(id: $id)
  }
`

const queryLeavesByStatus = gql`
  query Leaves($status: LeaveStatus) {
    leaves(status: $status) {
      id
      type
      status
      dates {
        leaveStart {
          current
        }
        leaveEnd {
          current
        }
      }
    }
  }
`

const queryLeaveBasic = gql`
  query Leave($changes: UpdateLeaveInput) {
    leave(changes: $changes) {
      ...partsLeaveBasic
    }
  }
  ${partsLeaveBasic}
`

const queryLeaveMetadata = gql`
  query Leave($changes: UpdateLeaveInput) {
    leave(changes: $changes) {
      ...partsLeaveBasic
      ...partsLeaveMetadata
      ...partsLeaveTpa
    }
  }
  ${partsLeaveBasic}
  ${partsLeaveMetadata}
  ${partsLeaveTpa}
`

const queryTpaLeave = gql`
  query TpaLeave($type: LeaveType!, $changes: CreateLeaveInput) {
    tpaLeave(type: $type, changes: $changes) {
      dueDate
      initialClaimID
      tpaClaimFirstReceivedAt
      type
    }
  }
`

const queryLeaveWithDates = gql`
  query Leave($changes: UpdateLeaveInput) {
    leave(changes: $changes) {
      ...partsLeaveBasic
      ...partsLeaveDates
    }
  }
  ${partsLeaveBasic}
  ${partsLeaveDates}
`

const queryLeaveWithDatesAndMetadata = gql`
  query Leave($changes: UpdateLeaveInput) {
    leave(changes: $changes) {
      metadata
      ...partsLeaveBasic
      ...partsLeaveDates
      ...partsLeaveTpa
      ...partsManagerActivationInfo
    }
  }
  ${partsLeaveBasic}
  ${partsLeaveDates}
  ${partsLeaveTpa}
  ${partsManagerActivationInfo}
`

const queryLeaveTimeline = gql`
  query Leave($changes: UpdateLeaveInput, $withDraft: Boolean) {
    leave(changes: $changes, withDraft: $withDraft) {
      ...partsLeaveTimeline
      ...partsLeaveTpa
    }
  }
  ${partsLeaveTimeline}
  ${partsLeaveTpa}
`

const queryLeaveJourneyMap = gql`
  query JourneyMapLeave {
    leave {
      ...partsLeaveJourneyMap
      ...partsLeaveTpa
    }
  }
  ${partsLeaveJourneyMap}
  ${partsLeaveTpa}
`

const queryAbsences = gql`
  query Absences {
    absences {
      ...partsAbsences
    }
  }
  ${partsAbsences}
`

const queryAbsencesForUser = gql`
  query Absences($userID: ID, $archived: Boolean) {
    absences(userID: $userID, archived: $archived) {
      ...partsAbsences
    }
  }
  ${partsAbsences}
`

const queryAbsencesDashboard = gql`
  query Absences($archived: Boolean) {
    absences(archived: $archived) {
      ...partsAbsencesDashboard
    }
  }
  ${partsAbsencesDashboard}
`

const queryAbsenceTimelineHistoryTimelines = gql`
  query AbsenceTimelineHistory($absenceID: ID!, $absenceTimelineHistoryID: ID) {
    absenceTimelineHistory(
      absenceID: $absenceID
      absenceTimelineHistoryID: $absenceTimelineHistoryID
    ) {
      startDate
      endDate
      status
      versionAt
      timeline {
        startDate
        endDate
        type
      }
    }
  }
`

const queryAlerts = gql`
  query alerts {
    alerts {
      content
      id
      title
      type
      cta {
        link
        text
        type
      }
    }
  }
`

const queryNotificationsFeed = gql`
  query getNotificationFeed(
    $type: NotificationType!
    $after: String
    $count: Int
  ) {
    notifications(type: $type, after: $after, count: $count) {
      cursor
      unreadCounter
      records {
        id
        ref
        title
        tag
        date
        read
      }
    }
  }
`

const querySurvey = gql`
  query survey($id: String!) {
    survey(id: $id) {
      id
      title
      status
      questions {
        id
        rank
        text
        description
        type
        responseRequired
        responses
        labels
        maxTextLength
        lastUserResponse {
          id
          questionID
          response
        }
      }
    }
  }
`

const querySurveySummaries = gql`
  query surveySummaries($type: LeaveGroup, $countryCode: String) {
    surveySummaries(type: $type, countryCode: $countryCode) {
      survey {
        id
        headline
      }
      summary {
        surveyQuestion {
          text
          description
          responses
          chart
        }
        data {
          response
          amount
        }
      }
    }
  }
`

const queryAnalyticsUsersAllTime = gql`
  query analytics(
    $period: Int
    $kind: AnalyticsChartKind!
    $type: LeaveGroup
    $countryCode: String
  ) {
    analytics(period: $period, type: $type, countryCode: $countryCode) {
      numbers {
        uniqueUsers
        timelineUsers
        journeyUsers
      }
      chartResult: chart(kind: $kind) {
        ...partsAnalyticsChart
      }
    }
  }
  ${partsAnalyticsChart}
`

const queryAnalyticsUsersWeeks = gql`
  query analytics($period: Int, $type: LeaveGroup, $countryCode: String) {
    analytics(period: $period, type: $type, countryCode: $countryCode) {
      numbers {
        uniqueUsers
        timelineUsers
        journeyUsers
      }
      chartResultUniqueUsers: chart(kind: UniqueUsers) {
        ...partsAnalyticsChart
      }
      chartResultTimelineUsers: chart(kind: TimelineUsers) {
        ...partsAnalyticsChart
      }
      chartResultJourneyUsers: chart(kind: JourneyUsers) {
        ...partsAnalyticsChart
      }
    }
  }
  ${partsAnalyticsChart}
`

const queryAnalyticsUsersWeeksNoChart = gql`
  query analytics($period: Int, $type: LeaveGroup, $countryCode: String) {
    analytics(period: $period, type: $type, countryCode: $countryCode) {
      numbers {
        uniqueUsers
        timelineUsers
        journeyUsers
      }
    }
  }
`

const queryAnalyticsActiveUsers = gql`
  query analytics($period: Int, $type: LeaveGroup, $countryCode: String) {
    analytics(period: $period, type: $type, countryCode: $countryCode) {
      numbers {
        activeUsers
        planningUsers
        onLeaveUsers
        backAtWorkUsers
      }
    }
  }
`

const queryAnalyticsManagerSupportActivationChart = gql`
  query analytics(
    $period: Int
    $kind: AnalyticsChartKind!
    $type: LeaveGroup
    $countryCode: String
  ) {
    analytics(period: $period, type: $type, countryCode: $countryCode) {
      chartResult: chart(kind: $kind) {
        ...partsAnalyticsChart
      }
    }
  }
  ${partsAnalyticsChart}
`

const queryAnalyticsManagerSupportActivationNumbers = gql`
  query analytics($period: Int, $type: LeaveGroup, $countryCode: String) {
    analytics(period: $period, type: $type, countryCode: $countryCode) {
      numbers {
        journeyUsers
        managerActivations
      }
    }
  }
`

const querySetBirthDate = gql`
  query setBirthDate($birthDate: Time, $subtype: LeaveSubType) {
    setBirthDate(birthDate: $birthDate, subtype: $subtype) {
      ...partsLeaveBasic
      ...partsLeaveDates
    }
  }
  ${partsLeaveBasic}
  ${partsLeaveDates}
`

export {
  mutationAcceptLeaveChanges,
  mutationAcceptTpaChanges,
  mutationActivateManagerForLeave,
  mutationArchiveAbsence,
  mutationArchiveLeave,
  mutationCancelLeaveChanges,
  mutationCloseAlert,
  mutationCompleteTransitionFlow,
  mutationCreateLeave,
  mutationCreateLeaveFromTpa,
  mutationCreateMetric,
  mutationCreateAbsenceSurveyQuestionResponse,
  mutationCreateSurveyQuestionResponse,
  mutationLogout,
  mutationReadAllNotifications,
  mutationReadNotification,
  mutationRemoveAbsence,
  mutationRemoveLeave,
  mutationRequestTpaUpdate,
  mutationResendConfirmation,
  mutationResetBirthForLeave,
  mutationResetToMiscarriage,
  mutationRevokeAllSessions,
  mutationRevokeSession,
  mutationSetBirthDate,
  mutationSetTpaSyncing,
  mutationStartLeave,
  mutationSuspendLeave,
  mutationToggleAbsenceJourneyMapItem,
  mutationToggleJourneyMapItem,
  mutationUpdateAbsenceSettings,
  mutationUpdateConfirmation,
  mutationUpdateLeave,
  mutationUpdateUser,
  queryAbsences,
  queryAbsencesDashboard,
  queryAbsencesForUser,
  queryAbsenceTimelineHistoryTimelines,
  queryAlerts,
  queryAnalyticsActiveUsers,
  queryAnalyticsManagerSupportActivationChart,
  queryAnalyticsManagerSupportActivationNumbers,
  queryAnalyticsUsersAllTime,
  queryAnalyticsUsersWeeks,
  queryAnalyticsUsersWeeksNoChart,
  queryClaimUpdateDetails,
  queryConfig,
  queryConfirmations,
  queryCountryConfigs,
  queryHolidays,
  queryLeaveBasic,
  queryLeaveJourneyMap,
  queryLeaveMetadata,
  queryLeaveTimeline,
  queryLeaveWithDates,
  queryLeaveWithDatesAndMetadata,
  queryLeavesByStatus,
  queryMe,
  queryNotificationsFeed,
  querySessions,
  querySetBirthDate,
  queryStates,
  querySurvey,
  querySurveySummaries,
  queryTpaLeave
}
