import React, { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { TpaApprovalState } from 'src/features/Leave/context'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import CreateLeaveNote from 'src/features/Leave/components/UI/CreateLeaveNote'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import NormalRadioButtonOption from '../NormalRadioButtonOption'

interface IProps {
  onOptionSelected: (state: TpaApprovalState) => void
  tpaApprovalState: TpaApprovalState
  leaveType: string
  onBack: () => void
  onNext: () => void
  showsSpinner?: boolean
}

const OptionContainer = styled.ul`
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
          width: 640px;
          max-width: 640px;
          background: ${props.theme.colors.light100};
        `
      : css`
          width: 100%;
        `}
`

export const TpaApprovedView = React.memo((props: IProps) => {
  const { onOptionSelected, tpaApprovalState, onBack, showsSpinner, onNext } =
    props
  const { t } = useTranslation()

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={tpaApprovalState === 'approved'}
        onClick={() => {
          onOptionSelected('approved')
        }}
      >
        {t('createLeave.tpaApproved.approved')}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={tpaApprovalState === 'notApproved'}
        onClick={() => {
          onOptionSelected('notApproved')
        }}
        hidesSeparator
      >
        {t('createLeave.tpaApproved.notApproved')}
      </NormalRadioButtonOption>
    ],
    [t, tpaApprovalState, onOptionSelected]
  )

  const isCreateLeaveDisabled: boolean = useMemo(
    () => tpaApprovalState === 'none',
    [tpaApprovalState]
  )

  const content: any = showsSpinner ? (
    <LoadingSpinner />
  ) : (
    <>
      <OptionContainer>{options}</OptionContainer>
      <CreateLeaveNote
        text={t('createLeave.tpaApproved.noProblemNote')}
        shows={tpaApprovalState === 'notApproved'}
        marginTop={24}
      />
      <CreateLeaveButtons
        backTitle={t('common.back')}
        nextTitle={t('common.next')}
        onBackClick={onBack}
        onNextClick={onNext}
        isNextDisabled={isCreateLeaveDisabled}
        nextAriaLabel={
          isCreateLeaveDisabled
            ? t('common.accessibilityText.selectOptionToContinue')
            : null
        }
      />
    </>
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle role={'alert'}>
        {t('createLeave.tpaApproved.title')}
      </CreateLeaveTitle>
      <CreateLeaveDescription>
        {t('createLeave.tpaApproved.description')}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

TpaApprovedView.displayName = 'TpaApprovedView'

export default TpaApprovedView
