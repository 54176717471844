import {
  completedPlanRoute,
  getRemindersPageRoute,
  journeyMapRoute,
  managerDashboardRoute,
  managerNotificationsRoute,
  notificationsRoute,
  outdatedPlanRoute,
  timelineRoute,
  userProfileRoute
} from 'src/routes/constants'
import last from 'lodash.last'
import { SECTION_NOTIFICATION_SETTINGS } from 'src/utils/anchors'
import { NavigateFunction } from 'react-router-dom'

const markAllAsRead = async (readAllNotifications: any, type: string) =>
  readAllNotifications(type)

const seeAll = (navigate: NavigateFunction, type: string) => {
  if (type === 'Employee') {
    navigate(notificationsRoute)
  } else {
    navigate(managerNotificationsRoute)
  }
}

const openItem = async (
  notification: INotification,
  navigate: NavigateFunction,
  readNotification: any,
  type: string
): Promise<any> => {
  try {
    const url: URL = new URL(notification.ref)
    const { href } = url
    // trying to parse employee notification ref
    if (url.protocol === 'journeymap:') {
      const parts: string[] = href.split('/')
      const leaveId: string = parts[parts.length - 2]
      const openItemId: string = leaveId + '/' + last(parts)
      navigate(journeyMapRoute, { state: { openItemId } })
    }

    // trying to parse manager notification ref
    if (url.protocol === 'absence:') {
      const parts: string[] = href.split('/')
      const absenceId: string = parts[parts.length - 2]
      const openItemId: string = absenceId + '/' + last(parts)
      navigate(managerDashboardRoute, {
        state: { openItemId, absenceId }
      })
    }

    // trying to parse timeline notification ref
    if (url.protocol === 'timeline:') {
      const parts: string[] = href.split('/')
      const action: string = parts[parts.length - 1]
      const leaveId: string = parts[parts.length - 2]
      if (action === 'start') {
        navigate(getRemindersPageRoute, { state: { leaveId } })
      } else if (action === 'outdated') {
        navigate(outdatedPlanRoute, { state: { leaveId } })
      } else if (action === 'completed') {
        navigate(completedPlanRoute, { state: { leaveId } })
      } else {
        navigate(timelineRoute)
      }
    }

    // trying to parse profile notification ref
    if (url.protocol === 'profile:') {
      let pathname: string = userProfileRoute
      if (notification.ref === 'profile://notifications/preferences') {
        pathname += SECTION_NOTIFICATION_SETTINGS
      }
      navigate(pathname)
    }
  } catch (e) {
    return undefined
  }

  if (!notification.read) {
    await readNotification(notification.id, type)
  }
}

export { markAllAsRead, seeAll, openItem }
