import React from 'react'
import { defaultUrlTransform } from 'react-markdown'
import { generateAnchor } from 'src/utils/anchors'

export const HeadingRenderer = (p: any) => {
  const slug = p.node.properties.id || generateAnchor(p.children)
  return React.createElement(p.node.tagName, { id: slug }, p.children)
}

export const LinkRenderer = (linkProps: any) => {
  const target: string =
    linkProps.href.indexOf('tel:') === 0 ? '_self' : '_blank'
  return (
    <a href={linkProps.href} target={target} rel="noopener noreferrer">
      {linkProps.children}
    </a>
  )
}

export const TelUrlTransformer = (url: string) =>
  url.startsWith('tel:') ? url : defaultUrlTransform(url)
