import merge from 'lodash.merge'
import {
  defaultCustomerConfig,
  ICustomerConfig,
  LeaveNoticeItem
} from './config'
import Workflow from 'src/utils/workflow'
import {
  ICountryCode,
  CreateLeaveStage,
  ITimelinePeriod
} from 'src/react-app-env'
import logo from 'src/assets/images/adobe-logo.svg'
import {
  VIEW_LEAVE_NOTICE_POLICY_CHANGE_KEY,
  VIEW_LEAVE_NOTICE_TPA_KEY
} from 'src/utils/ls'
import { isPublishedOrBabyArrivedLeave } from 'src/utils/leaveStatusUtils'
import { isPeriodNotSynced } from 'src/utils/periodUtils'

const tpa_approval_leave_types = ['Family', 'Military', 'Personal']

export default (): ICustomerConfig =>
  merge(defaultCustomerConfig(), {
    logo,
    tpa: 'Lincoln Financial',
    leave: {
      create: {
        hasTpaFlow: (workflow: Workflow<CreateLeaveStage>) =>
          workflow.current === 'parental' || workflow.current === 'medical',
        showPersonalNotes: true,
        useFamilyEndDate: false,
        workflow: {
          Medical: {
            init: 'medicalBeforeWeStart',
            medicalBeforeWeStart: 'medical',
            medical: ({ tpaLeave }) => (tpaLeave ? 'selectTpa' : null),
            selectTpa: null
          },
          Parental: {
            parental: ({ tpaLeave }) => (tpaLeave ? 'selectTpa' : null),
            selectTpa: null
          },
          Personal: {
            personal: ({ countryCode }) =>
              countryCode === 'US' ? 'tpaApproval' : null,
            tpaApproval: null
          },
          Military: {
            init: 'militaryTraining',
            militaryTraining: 'military',
            military: ({ isMilitaryIncludingReturn }) =>
              isMilitaryIncludingReturn ? 'militaryReturning' : 'tpaApproval',
            militaryReturning: 'tpaApproval',
            tpaApproval: null
          }
        }
      },
      timeline: {
        getLeaveNoticeItems: (leave: ILeave, countryCode: string) => {
          const result: LeaveNoticeItem[] = []

          if (leave.type === 'Personal' && countryCode === 'US') {
            result.push({
              noticeKey: VIEW_LEAVE_NOTICE_POLICY_CHANGE_KEY,
              titleKey: 'leaveNotice.viewPolicyInformation.title',
              bodyTextKey: 'leaveNotice.viewPolicyInformation.text'
            })
          }

          if (leave.type === 'Family') {
            result.push({
              noticeKey: VIEW_LEAVE_NOTICE_TPA_KEY,
              titleKey: 'leaveNotice.viewTpa.title',
              bodyTextKey: 'leaveNotice.viewTpa.text'
            })
          }

          return result
        },
        hideBlockIncomeSection: (leave: ILeave, countryCode: string) =>
          leave.type === 'Personal' && countryCode === 'US',
        hasDynamicItemID: (countryCode: any) =>
          countryCode === 'US' || countryCode === 'IE',
        showApprovedDuration: (leave: ILeave) => leave.type !== 'Family',
        useTpaApproval: (leaveType: ILeaveType, countryCode: ICountryCode) =>
          tpa_approval_leave_types.includes(leaveType) && countryCode === 'US'
      },
      tpaSync: {
        showSyncStatus: (leave: ILeave) =>
          isPublishedOrBabyArrivedLeave(leave.status),
        isPeriodNotSynced: (leave: ILeave, period: ITimelinePeriod) =>
          isPeriodNotSynced(leave, period)
      },
      planChange: {
        showHint: (leaveType: ILeaveType) => leaveType === 'Medical'
      }
    },
    userProfile: {
      profileSection: {
        hasDynamicPhoneMask: (countryCode: ICountryCode) => countryCode === 'IE'
      },
      tpaSyncSection: {
        showPromptTurnOff: (tpaSyncing: boolean) => tpaSyncing,
        showLastUpdate: false
      }
    }
  })
