import { ICustomerConfig } from './config'
import {
  isAdobe,
  isRoche,
  isGoogle,
  isIntuitive,
  isWorkday
} from 'src/utils/userUtils'
import Adobe from './adobe'
import Roche from './roche'
import Intuitive from './intuitive'
import Google from './google'
import Workday from './workday'

export const getCustomerConfig = (customer: string): ICustomerConfig => {
  const mapping = [
    { handle: isAdobe, config: Adobe },
    { handle: isRoche, config: Roche },
    { handle: isIntuitive, config: Intuitive },
    { handle: isGoogle, config: Google },
    { handle: isWorkday, config: Workday }
  ]

  const config = mapping.find(item => item.handle(customer))?.config
  return config ? config() : null
}
