import React, { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NormalRadioButtonOption from 'src/features/Leave/components/NormalRadioButtonOption'
import styled, { css } from 'styled-components'
import { TransitionStage } from '../../TransitionFlowContainer'
import TransitionStepsView from '../TransitionStepsView'
import moment from 'moment'
import { DATE_FORMAT } from 'src/utils/dateUtils'

interface IProps {
  name: TransitionStage
  setCurrentStage: (stage: TransitionStage) => void
  onComplete: () => void
  disabilityEnd: string
}

type disabilityState = 'fullyRecovered' | 'needAdditionalDisability' | 'none'

const OptionContainer = styled.ul`
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
          max-width: 640px;
          background: ${props.theme.colors.light100};
          margin-bottom: 32px;
        `
      : css`
          width: 100%;
        `}
`

export const DisabilityTimeView = React.memo((props: IProps) => {
  const { name, setCurrentStage, onComplete, disabilityEnd } = props
  const { t } = useTranslation()
  const [disabilityTimeState, setDisabilityTimeState] =
    useState<disabilityState>('none')

  const disabilityEndDate: string = useMemo(
    () =>
      moment(disabilityEnd, 'YYYY-MM-DD HH:mm:ss').utc().format(DATE_FORMAT),
    [disabilityEnd]
  )

  const onNextClicked = () => {
    if (disabilityTimeState === 'fullyRecovered') {
      setCurrentStage('babyCurrentSituation')
    } else {
      onComplete()
      setCurrentStage('finalActionDisabilityTime')
    }
  }

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={disabilityTimeState === 'fullyRecovered'}
        onClick={() => {
          setDisabilityTimeState('fullyRecovered')
        }}
      >
        {t(`transitionFlow.pages.${name}.options.fullyRecovered`, {
          date: disabilityEndDate
        })}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={disabilityTimeState === 'needAdditionalDisability'}
        onClick={() => {
          setDisabilityTimeState('needAdditionalDisability')
        }}
        hidesSeparator
      >
        {t(`transitionFlow.pages.${name}.options.needAdditionalDisability`)}
      </NormalRadioButtonOption>
    ],
    [disabilityTimeState, t, name, disabilityEndDate]
  )

  return (
    <TransitionStepsView
      name={name}
      onNextClicked={onNextClicked}
      hidesCancelButton
      isNextDisabled={disabilityTimeState === 'none'}
      nextButtonTextKey={'common.next'}
      disabilityEndDate={disabilityEndDate}
    >
      <OptionContainer>{options}</OptionContainer>
    </TransitionStepsView>
  )
})

DisabilityTimeView.displayName = 'DisabilityTimeView'

export default DisabilityTimeView
