import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as serviceWorker from 'src/serviceWorkerRegistration'

export interface IServiceWorkerContext {
  isUpdateAvailable: boolean
  updateAssets: () => void
}

const ServiceWorkerContext = React.createContext(null)

export const ServiceWorkerProvider = (props: any): any => {
  const { children } = props
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)
  const [isUpdateAvailable, setUpdateAvailable] = useState(false)

  const register = () => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        const { waiting, installing } = registration
        if (installing) {
          setWaitingServiceWorker(installing)
          setUpdateAvailable(true)
        }
        if (waiting) {
          setWaitingServiceWorker(waiting)
          setUpdateAvailable(true)
        }
      }
    })
  }

  useEffect(() => {
    register()
  }, [])

  const contextValue: IServiceWorkerContext = useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        } else {
          if (window.location.pathname !== '/') {
            window.location.reload()
          }
        }
      }
    }),
    [isUpdateAvailable, waitingServiceWorker]
  )

  return (
    <ServiceWorkerContext.Provider value={contextValue}>
      {children}
    </ServiceWorkerContext.Provider>
  )
}

export default () => useContext(ServiceWorkerContext)
