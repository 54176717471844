import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import { useTranslation } from 'react-i18next'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import NormalRadioButtonOption from '../NormalRadioButtonOption'
import CreateLeaveNote from '../UI/CreateLeaveNote'
import ScreenContext from 'src/contexts/ScreenContext'

const OptionContainer = styled.ul`
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
          max-width: 640px;
          background: ${props.theme.colors.light100};
        `
      : css`
          width: 100%;
        `}
`

export const SelectUserIntentView = React.memo(() => {
  const { user, userIntent, setUserIntent, nextCreateLeaveStage } =
    useContext<ICreateLeaveContext>(Context)
  const { t } = useTranslation()

  const { i18Context } = useContext(ScreenContext)

  const title: string = useMemo(
    () =>
      user?.firstName
        ? t('createLeave.selectUserIntent.title', {
            user: user.firstName,
            context: i18Context
          })
        : t('common.welcome'),
    [t, user, i18Context]
  )

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={userIntent === 'Leaver'}
        onClick={() => {
          setUserIntent('Leaver')
        }}
      >
        {t('createLeave.selectUserIntent.leaver')}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={userIntent === 'Shopper'}
        onClick={() => {
          setUserIntent('Shopper')
        }}
        hidesSeparator
      >
        {t('createLeave.selectUserIntent.shopper')}
      </NormalRadioButtonOption>
    ],
    [userIntent, setUserIntent, t]
  )

  const onSkipClick = useCallback(() => {
    setUserIntent('Unknown')
    nextCreateLeaveStage()
  }, [nextCreateLeaveStage, setUserIntent])

  const content: any = useMemo(
    () => (
      <>
        <OptionContainer>{options}</OptionContainer>
        <CreateLeaveNote
          text={t('createLeave.selectUserIntent.note')}
          shows
          marginTop={32}
        />
        <CreateLeaveButtons
          backTitle={t('createLeave.selectUserIntent.buttonSkip')}
          nextTitle={t('common.next')}
          onBackClick={onSkipClick}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={userIntent === 'Unknown'}
        />
      </>
    ),
    [options, t, onSkipClick, userIntent, nextCreateLeaveStage]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>{title}</CreateLeaveTitle>
      <CreateLeaveDescription marginBottom={'32px'}>
        {t('createLeave.selectUserIntent.description')}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

SelectUserIntentView.displayName = 'SelectUserIntentView'

export default SelectUserIntentView
