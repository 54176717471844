import React from 'react'
import withRouter from 'src/components/hooks/useRouter'
import QueryExecutor, { onQueryRejection } from 'src/graphql/QueryExecutor'
import { setupTranslation } from 'src/i18n'
import {
  mutationCreateLeave,
  mutationLogout,
  mutationResendConfirmation,
  mutationUpdateConfirmation,
  mutationUpdateLeave,
  mutationUpdateUser,
  mutationRemoveLeave,
  queryConfig,
  queryCountryConfigs,
  queryConfirmations,
  queryMe,
  mutationStartLeave,
  mutationToggleJourneyMapItem,
  mutationToggleAbsenceJourneyMapItem,
  mutationSetTpaSyncing,
  mutationAcceptTpaChanges,
  mutationArchiveAbsence,
  queryNotificationsFeed,
  mutationReadNotification,
  mutationReadAllNotifications,
  queryLeaveTimeline,
  queryLeaveJourneyMap,
  queryLeaveBasic,
  queryTpaLeave,
  mutationSetBirthDate,
  mutationResetBirthForLeave,
  mutationResetToMiscarriage,
  mutationActivateManagerForLeave,
  queryAbsences,
  queryAbsencesDashboard,
  mutationUpdateAbsenceSettings,
  mutationCreateSurveyQuestionResponse,
  mutationRevokeSession,
  mutationRevokeAllSessions,
  mutationCreateMetric,
  mutationSuspendLeave,
  queryLeavesByStatus,
  queryLeaveWithDatesAndMetadata,
  queryStates,
  mutationAcceptLeaveChanges,
  mutationCancelLeaveChanges,
  queryAbsencesForUser,
  mutationCompleteTransitionFlow,
  mutationRemoveAbsence,
  mutationCreateLeaveFromTpa,
  queryHolidays,
  queryAbsenceTimelineHistoryTimelines,
  queryAlerts,
  mutationCloseAlert,
  queryClaimUpdateDetails,
  mutationRequestTpaUpdate,
  mutationCreateAbsenceSurveyQuestionResponse
} from 'src/graphql/queries'
import get from 'lodash.get'
import cloneDeep from 'lodash.clonedeep'
import {
  IGraphqlExecutorOptions,
  IGraphqlQueries,
  UserRole
} from 'src/react-app-env'
import { welcomeRoute } from 'src/routes/constants'
import { DataProxy, ApolloCache, ApolloQueryResult } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { convertJourneyMapDueDateToMoment } from 'src/utils/journeyMap'
import { getIsEmployee } from 'src/utils/userUtils'
import { useNavigate } from 'react-router-dom'

export default (WrappedContainer: any): any => {
  const Container = React.memo((props: any) => {
    const { client } = props
    const navigate = useNavigate()

    const queryFunc = async (
      params: any,
      options?: IGraphqlExecutorOptions
    ) => {
      const queryOptions: any = { ...params }
      queryOptions.fetchPolicy =
        options && options.fetchPolicy ? options.fetchPolicy : 'cache-first'
      return client.query(queryOptions)
    }

    const mutateFunc = async (
      params: any,
      options?: IGraphqlExecutorOptions
    ) => {
      const mutationOptions: any = { ...params }
      return client.mutate(mutationOptions).catch((reason: any) => {
        onQueryRejection(reason, navigate, options)
      })
    }

    const fetchMe = async (options?: IGraphqlExecutorOptions) =>
      queryFunc({ query: queryMe }, options)
        .then((result: any) => get(result, 'data.me', null))
        .then((user: any) => {
          setupTranslation(user)
          return user
        })
        .catch((reason: any) => {
          onQueryRejection(reason, navigate, {
            ...options,
            notFound: () => {
              client.clearStore().then(() => {
                navigate(welcomeRoute)
              })
            }
          })
        })

    const fetchClaimUpdateDetails = async (options?: IGraphqlExecutorOptions) =>
      new Promise(
        (resolve: (claimUpdateDetails: IClaimUpdateDetails) => void) => {
          QueryExecutor(
            async () => {
              const result: ApolloQueryResult<any> = await queryFunc(
                { query: queryClaimUpdateDetails },
                options
              )
              const claimUpdateDetails: IClaimUpdateDetails = get(
                result,
                'data.claimUpdateDetails'
              )
              resolve(claimUpdateDetails)
            },
            navigate,
            options
          )
        }
      )

    const fetchConfig = async (options?: IGraphqlExecutorOptions) =>
      new Promise((resolve: (config: IConfig) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              { query: queryConfig },
              options
            )
            const config: IConfig = get(result, 'data.configuration')
            resolve(config)
          },
          navigate,
          options
        )
      })

    const fetchCountryConfigs = async (options?: IGraphqlExecutorOptions) =>
      new Promise((resolve: (config: ICountryConfig[]) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              { query: queryCountryConfigs },
              options
            )
            const configs: ICountryConfig[] = get(
              result,
              'data.countryConfigurations'
            )
            resolve(configs)
          },
          navigate,
          options
        )
      })

    const fetchHolidays = async (
      start: string,
      end: string,
      options?: IGraphqlExecutorOptions
    ) =>
      new Promise((resolve: (config: IHoliday[]) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              { query: queryHolidays, variables: { start, end } },
              options
            )
            const holidays: IHoliday[] = get(result, 'data.holidays')
            resolve(holidays)
          },
          navigate,
          options
        )
      })

    const fetchStates = async (options?: IGraphqlExecutorOptions) =>
      new Promise((resolve: (states: string[]) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              { query: queryStates },
              options
            )
            const states: string[] = get(result, 'data.states')
            resolve(states)
          },
          navigate,
          options
        )
      })

    const getRidOfJesusBirthdayEndDate = (leave: ILeave) => {
      if (leave?.dates?.leaveEnd?.current === '0001-01-01T00:00:00Z') {
        const clone = cloneDeep(leave)
        clone.dates.leaveEnd = null
        return clone
      }
      return leave
    }

    const fetchLeaveWithQuery = async (
      query: any,
      options: IGraphqlExecutorOptions,
      changes?: any
    ) =>
      new Promise(async (resolve: (leave: ILeave) => void) => {
        const user: IUser = await fetchMe({ fetchPolicy: 'cache-first' })
        if (!getIsEmployee(user?.roles as UserRole[])) {
          return
        }

        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              { query, variables: changes ? { changes } : {} },
              options
            )
            const leave: ILeave = getRidOfJesusBirthdayEndDate(
              get(result, 'data.leave', null)
            )

            resolve({
              ...leave,
              journeyMap: convertJourneyMapDueDateToMoment(leave?.journeyMap)
            })
          },
          navigate,
          options
        )
      })

    const fetchLeaveWithUser = async (
      options: IGraphqlExecutorOptions,
      changes?: any,
      withDraft?: boolean
    ) =>
      new Promise(async (resolve: (result: any) => void) => {
        const user: IUser = await fetchMe({ fetchPolicy: 'cache-first' })
        if (!getIsEmployee(user?.roles as UserRole[])) {
          return {}
        }

        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              {
                query: queryLeaveTimeline,
                variables: changes ? { changes, withDraft } : {}
              },
              options
            )
            const leave: ILeave = getRidOfJesusBirthdayEndDate(
              get(result, 'data.leave', null)
            )

            resolve({
              leave: {
                ...leave,
                journeyMap: convertJourneyMapDueDateToMoment(leave?.journeyMap)
              },
              user
            })
          },
          navigate,
          options
        )
      })

    const fetchAbsenceTimelineHistoryWithQuery = async (
      query: any,
      absenceID: string,
      absenceTimelineHistoryID?: string,
      options?: IGraphqlExecutorOptions
    ) =>
      new Promise(
        async (
          resolve: (absenceTimelineHistory: IAbsenceTimelineHistoryInfo) => void
        ) => {
          QueryExecutor(
            async () => {
              const variables = {
                absenceID,
                ...(absenceTimelineHistoryID
                  ? { absenceTimelineHistoryID }
                  : {})
              }
              const result: ApolloQueryResult<any> = await queryFunc(
                { query, variables },
                options
              )
              const absenceTimelineHistory: IAbsenceTimelineHistoryInfo = get(
                result,
                'data.absenceTimelineHistory',
                null
              )

              resolve(absenceTimelineHistory)
            },
            navigate,
            options
          )
        }
      )

    const fetchAbsencesWithQuery = async (
      query: any,
      archived?: boolean,
      options?: IGraphqlExecutorOptions
    ) =>
      new Promise((resolve: (absences: IAbsence[]) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              {
                query,
                variables: archived !== null ? { archived } : {}
              },
              options
            )
            const absences: IAbsence[] = get(result, 'data.absences', null).map(
              (absence: IAbsence) => ({
                ...absence,
                journeyMap: convertJourneyMapDueDateToMoment(absence.journeyMap)
              })
            )
            resolve(absences)
          },
          navigate,
          options
        )
      })

    const fetchSuspendedLeaves = async (options: IGraphqlExecutorOptions) =>
      new Promise((resolve: (leaves: ILeave[]) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              {
                query: queryLeavesByStatus,
                variables: { status: 'Suspended' }
              },
              options
            )
            const leaves: ILeave[] = get(result, 'data.leaves', [])
            resolve(leaves)
          },
          navigate,
          options
        )
      })

    const fetchLeaveBasic = async (
      options: IGraphqlExecutorOptions,
      changes?: any
    ) => fetchLeaveWithQuery(queryLeaveBasic, options, changes)

    const fetchLeaveWithDatesAndMetadata = async (
      options: IGraphqlExecutorOptions,
      changes?: any
    ) => fetchLeaveWithQuery(queryLeaveWithDatesAndMetadata, options, changes)

    const fetchLeaveTimeline = async (
      options: IGraphqlExecutorOptions,
      changes?: any
    ) => fetchLeaveWithQuery(queryLeaveTimeline, options, changes)

    const fetchLeaveJourneyMap = async (options: IGraphqlExecutorOptions) => {
      const leave = await fetchLeaveWithQuery(queryLeaveJourneyMap, options)
      if (!leave?.journeyMap && options.fetchPolicy !== 'network-only') {
        options.fetchPolicy = 'network-only'
        return fetchLeaveWithQuery(queryLeaveJourneyMap, options)
      }
      return leave
    }

    const fetchTpaLeave = async (
      type: string,
      dueDate?: string,
      startDate?: string,
      endDate?: string,
      options?: IGraphqlExecutorOptions
    ) =>
      new Promise((resolve: (leave: ILeave) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              {
                query: queryTpaLeave,
                variables: { type, changes: { dueDate, startDate, endDate } }
              },
              options
            )
            resolve(get(result, 'data.tpaLeave'))
          },
          navigate,
          options
        )
      })

    const fetchAbsences = async (options?: IGraphqlExecutorOptions) =>
      fetchAbsencesWithQuery(queryAbsences, null, options)

    const fetchAbsencesDashboard = async (
      archived: boolean,
      options?: IGraphqlExecutorOptions
    ) => fetchAbsencesWithQuery(queryAbsencesDashboard, archived, options)

    const fetchAbsenceTimelineHistory = async (
      absenceID: string,
      absenceTimelineHistoryID?: string,
      options?: IGraphqlExecutorOptions
    ) =>
      fetchAbsenceTimelineHistoryWithQuery(
        queryAbsenceTimelineHistoryTimelines,
        absenceID,
        absenceTimelineHistoryID,
        options
      )

    const fetchNotificationFeed = async (
      type: string,
      after: string,
      count: number,
      options: IGraphqlExecutorOptions
    ) =>
      new Promise((resolve: (notificationsFeed: INotificationFeed) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              {
                query: queryNotificationsFeed,
                variables: { type, after, count }
              },
              options
            )
            const notificationsFeed: INotificationFeed = get(
              result,
              'data.notifications',
              0
            )

            resolve(notificationsFeed)
          },
          navigate,
          options
        )
      })

    const updateConfirmationCode = async (
      code: string,
      kind: string,
      options: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationUpdateConfirmation,
          variables: { code, kind },
          refetchQueries: [{ query: queryMe }],
          awaitRefetchQueries: true
        },
        options
      ).then(() => {
        const userResult: any = client.readQuery({ query: queryMe })
        return get(userResult, 'me')
      })

    const resendConfirmationCode = async (
      kind: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationResendConfirmation,
          variables: { kind }
        },
        options
      ).then((result: any) => get(result, 'data.resendConfirmation'))

    const fetchConfirmations = async (options?: IGraphqlExecutorOptions) =>
      new Promise((resolve: (confirmations: IConfirmation[]) => void) => {
        QueryExecutor(
          async () => {
            const result: any = await queryFunc({
              query: queryConfirmations
            })
            const confirmations: IConfirmation[] = get(
              result,
              'data.me.confirmations',
              []
            )
            resolve(confirmations)
          },
          navigate,
          options
        )
      })

    const updateUser = async (
      changes: IUserInput,
      options: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationUpdateUser,
          variables: { changes }
        },
        options
      ).then((result: any) => get(result, 'data.updateUser'))

    const createLeaveFromTpa = async (
      extra: IExtraCreateInput,
      type: string,
      dueDate: string,
      startDate: string,
      endDate: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateLeaveFromTpa,
          variables: { type, changes: { dueDate, extra, startDate, endDate } }
        },
        options
      ).then((result: any) => get(result, 'data.createLeaveFromTpa'))

    const createLeaveParental = async (
      type: string,
      dueDate: string,
      userIntent: IUserIntent,
      extra?: IExtraCreateInput,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateLeave,
          variables: { type, changes: { dueDate, extra, userIntent } }
        },
        options
      ).then((result: any) => get(result, 'data.createLeave'))

    const createLeaveMedicalOrPersonal = async (
      type: string,
      startDate: string,
      endDate: string,
      approvedState: boolean,
      injuryState: boolean,
      userIntent: IUserIntent,
      extra?: IExtraCreateInput,
      options?: IGraphqlExecutorOptions
    ) => {
      const changes: any = {
        startDate,
        endDate,
        extra: { approvedState, ...extra },
        userIntent
      }
      if (injuryState) {
        changes.extra.injuryState = injuryState
      }
      return mutateFunc(
        {
          mutation: mutationCreateLeave,
          variables: {
            type,
            changes
          }
        },
        options
      ).then((result: any) => get(result, 'data.createLeave'))
    }

    const createLeaveMilitary = async (
      startDate: string,
      endDate: string,
      backToWorkDate: string,
      approvedState: boolean,
      userIntent: IUserIntent,
      options?: IGraphqlExecutorOptions
    ) => {
      const changes: any = {
        startDate,
        extra: { approvedState, activeDutyEndDate: endDate },
        userIntent
      }
      if (backToWorkDate) {
        changes.endDate = backToWorkDate
      }
      return mutateFunc(
        {
          mutation: mutationCreateLeave,
          variables: {
            type: 'Military',
            changes
          }
        },
        options
      ).then((result: any) => get(result, 'data.createLeave'))
    }

    const createLeaveFamily = async (
      startDate: string,
      userIntent: IUserIntent,
      endDate?: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateLeave,
          variables: {
            type: 'Family',
            changes: { startDate, endDate, userIntent }
          }
        },
        options
      ).then((result: any) => get(result, 'data.createLeave'))

    const createLeaveSickness = async (
      startDate: string,
      endDate: string,
      userIntent: IUserIntent,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateLeave,
          variables: {
            type: 'Sickness',
            changes: { startDate, endDate, userIntent }
          }
        },
        options
      ).then((result: any) => get(result, 'data.createLeave'))

    const createLeaveMiscarriage = async (
      type: string,
      subtype: string,
      dueDate: string,
      startDate: string,
      userIntent: IUserIntent,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateLeave,
          variables: {
            type,
            changes: { subtype, dueDate, startDate, userIntent }
          }
        },
        options
      ).then((result: any) => get(result, 'data.createLeave'))

    const createLeaveSabbatical = async (
      startDate: string,
      userIntent: IUserIntent,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateLeave,
          variables: {
            type: 'Sabbatical',
            changes: { startDate, userIntent }
          }
        },
        options
      ).then((result: any) => get(result, 'data.createLeave'))

    const setLeaveCache = (store: DataProxy, leave: ILeave) => {
      const data = { leave: { ...leave } }
      store.writeQuery({
        query: queryLeaveBasic,
        data
      })
      store.writeQuery({
        query: queryLeaveTimeline,
        data
      })
      if (data.leave !== null) {
        data.leave.journeyMap = null
      }
      store.writeQuery({
        query: queryLeaveJourneyMap,
        data
      })
    }

    const mutateLeave = async (
      mutateQuery: any,
      dataKey: string,
      variables: any,
      options?: IGraphqlExecutorOptions
    ) => {
      // need to refetch journey map to have journey red dot working
      // in header navigation
      let refetchQueries: any = []
      let awaitRefetchQueries = false
      try {
        const data: any = await client.readQuery({
          query: queryLeaveJourneyMap
        })
        if (data.leave?.journeyMap) {
          refetchQueries = [{ query: queryLeaveJourneyMap }]
          awaitRefetchQueries = true
        }
      } catch (_) {}

      return mutateFunc(
        {
          mutation: mutateQuery,
          variables,
          refetchQueries,
          awaitRefetchQueries,
          update: (store: ApolloCache<any>, mutationResult: any) => {
            setLeaveCache(store, get(mutationResult, dataKey))
          }
        },
        options
      ).then((result: any) => {
        const leave: ILeave = getRidOfJesusBirthdayEndDate(
          get(result, dataKey, null)
        )
        return leave
      })
    }

    const updateLeave = async (
      variables: any,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateLeave(mutationUpdateLeave, 'data.updateLeave', variables, options)

    const removeLeave = async (
      id: string,
      options?: IGraphqlExecutorOptions
    ) => {
      const result: any = await mutateFunc(
        { mutation: mutationRemoveLeave, variables: { id } },
        options
      )
      return get(result, 'data.removeLeave')
    }

    const suspendLeave = async (
      shouldAddNotifyManagerField: boolean,
      notifyManager: boolean,
      options?: IGraphqlExecutorOptions
    ) => {
      const variables: any = shouldAddNotifyManagerField
        ? { notifyManager }
        : {}
      return mutateFunc(
        {
          mutation: mutationSuspendLeave,
          variables,
          refetchQueries: [
            { query: queryMe },
            {
              query: queryNotificationsFeed,
              variables: { type: 'Employee', after: null, count: 4 }
            }
          ],
          awaitRefetchQueries: true
        },
        options
      ).then(() => {
        client.writeQuery({ query: queryLeaveBasic, data: { leave: null } })
        client.writeQuery({
          query: queryLeaveTimeline,
          data: { leave: null }
        })
        client.writeQuery({
          query: queryLeaveJourneyMap,
          data: { leave: null }
        })
        return true
      })
    }

    const startLeave = async (options?: IGraphqlExecutorOptions) =>
      mutateLeave(mutationStartLeave, 'data.startLeave', null, options)

    const saveLeaveChanges = async (options?: IGraphqlExecutorOptions) =>
      mutateLeave(
        mutationAcceptLeaveChanges,
        'data.saveLeaveChanges',
        null,
        options
      )

    const cancelLeaveChanges = async (options?: IGraphqlExecutorOptions) =>
      mutateLeave(
        mutationCancelLeaveChanges,
        'data.cancelLeaveChanges',
        null,
        options
      )

    const setBirthDate = async (
      variables: any,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateLeave(
        mutationSetBirthDate,
        'data.setBirthForLeave',
        variables,
        options
      )

    const resetBirthForLeave = async (options?: IGraphqlExecutorOptions) =>
      mutateLeave(
        mutationResetBirthForLeave,
        'data.resetBirthForLeave',
        null,
        options
      )

    const resetToMiscarriage = async (
      variables: any,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateLeave(
        mutationResetToMiscarriage,
        'data.resetToMiscarriage',
        variables,
        options
      )

    const toggleJourneyMapItem = async (
      id: string,
      options?: IGraphqlExecutorOptions
    ) => {
      const data = cloneDeep(
        client.readQuery({
          query: queryLeaveJourneyMap
        })
      )
      const jmItem = data.leave.journeyMap.find((item: any) => item.id === id)
      if (jmItem) {
        jmItem.completed = !jmItem.completed
        client.writeQuery({
          query: queryLeaveJourneyMap,
          data
        })
      }
      return mutateFunc(
        {
          mutation: mutationToggleJourneyMapItem,
          variables: { id }
        },
        options
      ).then((result: any) => get(result, 'data.toggleJourneyMapItem'))
    }

    const toggleAbsenceJourneyMapItem = async (
      id: string,
      variables: any,
      options?: IGraphqlExecutorOptions
    ) => {
      const data = cloneDeep(
        client.readQuery({
          query: queryAbsencesForUser,
          variables
        })
      )
      let jmItem
      for (const absence of data.absences) {
        const found: IJourneyMapItem = absence.journeyMap.find(
          (item: any) => item.id === id
        )
        if (found) {
          jmItem = found
          break
        }
      }
      if (jmItem) {
        jmItem.completed = !jmItem.completed
        jmItem.completedBy = null
        jmItem.completedAt = null
        client.writeQuery({
          query: queryAbsencesForUser,
          variables,
          data
        })
      }
      return mutateFunc(
        {
          mutation: mutationToggleAbsenceJourneyMapItem,
          variables: { id, completed: jmItem.completed }
        },
        options
      ).then((result: any) => get(result, 'data.toggleAbsenceJourneyMapItem'))
    }

    const updateAbsenceSettings = async (
      id: string,
      changes: IAbsenceSettingsInput,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationUpdateAbsenceSettings,
          variables: { id, changes }
        },
        options
      ).then((result: any) => get(result, 'data.updateAbsenceSettings'))

    const archiveAbsence = async (
      id: string,
      archived: boolean,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationArchiveAbsence,
          variables: { id, archived }
        },
        options
      ).then((result: any) => get(result, 'data.archiveAbsence'))

    const removeAbsence = async (
      id: string,
      options?: IGraphqlExecutorOptions
    ) => {
      const result: any = await mutateFunc(
        { mutation: mutationRemoveAbsence, variables: { id } },
        options
      )
      return get(result, 'data.removeAbsence')
    }

    const setTpaSyncing = async (
      value: boolean,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationSetTpaSyncing,
          variables: { value }
        },
        options
      ).then((result: any) => get(result, 'data.setTpaSyncing'))

    const acceptTpaChanges = async (
      clearHistory: boolean,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        { mutation: mutationAcceptTpaChanges, variables: { clearHistory } },
        options
      ).then((result: any) => get(result, 'data.acceptTpaChanges'))

    const readNotification = async (
      id: string,
      type: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationReadNotification,
          variables: { id },
          refetchQueries: [
            {
              query: queryNotificationsFeed,
              variables: { type, after: null, count: 4 }
            }
          ],
          awaitRefetchQueries: true
        },
        options
      ).then((result: any) => get(result, 'data.readNotification'))

    const readAllNotifications = async (
      type: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationReadAllNotifications,
          variables: { type },
          refetchQueries: [
            {
              query: queryNotificationsFeed,
              variables: { type, after: null, count: 4 }
            }
          ],
          awaitRefetchQueries: true
        },
        options
      ).then((result: any) => get(result, 'data.readAllNotifications'))

    const activateManagerForLeave = async (options?: IGraphqlExecutorOptions) =>
      mutateFunc(
        {
          mutation: mutationActivateManagerForLeave,
          refetchQueries: [{ query: queryLeaveBasic }],
          awaitRefetchQueries: true
        },
        options
      ).then((result: any) => get(result, 'data.activateManagerForLeave'))

    const createAbsenceSurveyQuestionResponse = async (
      absenceID: string,
      surveyID: string,
      response: ISurveyResponseInput,
      surveyResponseID?: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateAbsenceSurveyQuestionResponse,
          variables: { absenceID, surveyID, response, surveyResponseID }
        },
        options
      ).then((result: any) =>
        get(result, 'data.createAbsenceSurveyQuestionResponse')
      )

    const createSurveyQuestionResponse = async (
      surveyID: string,
      response: ISurveyResponseInput,
      surveyResponseID?: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateSurveyQuestionResponse,
          variables: { surveyID, response, surveyResponseID }
        },
        options
      ).then((result: any) => get(result, 'data.createSurveyQuestionResponse'))

    const requestTpaUpdate = async (options?: IGraphqlExecutorOptions) =>
      mutateFunc(
        {
          mutation: mutationRequestTpaUpdate
        },
        options
      ).then((result: any) => get(result, 'data.requestTpaUpdate'))

    const revokeSession = async (
      id: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationRevokeSession,
          variables: { id }
        },
        options
      ).then((result: any) => get(result, 'data.revokeSession'))

    const revokeAllSessions = async (options?: IGraphqlExecutorOptions) =>
      mutateFunc(
        {
          mutation: mutationRevokeAllSessions
        },
        options
      ).then((result: any) => get(result, 'data.revokeAllSessions'))

    const createMetric = async (
      variables: IMutationCreateMetricArgs,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCreateMetric,
          variables
        },
        options
      ).then((result: any) => get(result, 'data.createMetric'))

    const logout = async (
      options?: IGraphqlExecutorOptions
    ): Promise<boolean> =>
      mutateFunc(
        {
          mutation: mutationLogout
        },
        options
      )
        .then(async (result: any) => {
          await client.clearStore()
          return get(result, 'data.logout')
        })
        .catch(() => false)

    const completeTransitionFlow = async (
      transitionFlowType: string,
      options?: IGraphqlExecutorOptions
    ) =>
      mutateFunc(
        {
          mutation: mutationCompleteTransitionFlow,
          variables: { transitionFlowType }
        },
        options
      ).then((result: any) => get(result, 'data.completeTransitionFlow'))

    const fetchAlerts = async (options?: IGraphqlExecutorOptions) =>
      new Promise((resolve: (alerts: IAlert[]) => void) => {
        QueryExecutor(
          async () => {
            const result: ApolloQueryResult<any> = await queryFunc(
              { query: queryAlerts },
              options
            )
            const alerts: IAlert[] = get(result, 'data.alerts')
            resolve(alerts)
          },
          navigate,
          options
        )
      })

    const closeAlert = async (id: string, options?: IGraphqlExecutorOptions) =>
      mutateFunc(
        {
          mutation: mutationCloseAlert,
          variables: { id }
        },
        options
      ).then((result: any) => get(result, 'data.closeAlert'))

    const queries: IGraphqlQueries = {
      client: props.client,
      fetchAlerts,
      fetchMe,
      fetchClaimUpdateDetails,
      fetchConfig,
      fetchCountryConfigs,
      fetchStates,
      fetchSuspendedLeaves,
      fetchLeaveBasic,
      fetchLeaveWithDatesAndMetadata,
      fetchLeaveTimeline,
      fetchLeaveWithUser,
      fetchLeaveJourneyMap,
      fetchTpaLeave,
      fetchAbsences,
      fetchAbsencesDashboard,
      fetchAbsenceTimelineHistory,
      fetchNotificationFeed,
      fetchHolidays,
      fetchConfirmations,
      updateConfirmationCode,
      resendConfirmationCode,
      updateUser,
      closeAlert,
      createLeaveParental,
      createLeaveFromTpa,
      createLeaveMedicalOrPersonal,
      createLeaveMilitary,
      createLeaveFamily,
      createLeaveSickness,
      createLeaveMiscarriage,
      createLeaveSabbatical,
      updateLeave,
      removeLeave,
      suspendLeave,
      startLeave,
      setBirthDate,
      resetBirthForLeave,
      resetToMiscarriage,
      toggleJourneyMapItem,
      toggleAbsenceJourneyMapItem,
      updateAbsenceSettings,
      setTpaSyncing,
      acceptTpaChanges,
      archiveAbsence,
      removeAbsence,
      readNotification,
      readAllNotifications,
      activateManagerForLeave,
      createAbsenceSurveyQuestionResponse,
      createSurveyQuestionResponse,
      requestTpaUpdate,
      revokeSession,
      revokeAllSessions,
      createMetric,
      logout,
      saveLeaveChanges,
      cancelLeaveChanges,
      completeTransitionFlow
    }

    return <WrappedContainer queries={queries} {...props} />
  })

  Container.displayName = 'Container'

  const wApollo: any = withApollo(Container)
  return withRouter(wApollo)
}
