const welcomeRoute = '/'

const createLeaveRoute = '/leave/start'
const outdatedPlanRoute = '/leave/outdated'
const completedPlanRoute = '/leave/completed'

const claimUpdateRoute = '/claim-update'
const closeAccountRoute = '/close'
const eligibilityNoticePageRoute = '/eligibility'
const errorPageRoute = '/error'
const getRemindersPageRoute = '/getreminders'
const globalSurveysRoute = '/surveys/:id/response/:value'
const helpCenterRoute = '/help'
const hrAdminDashboardRoute = '/hr/dashboard'
const infoPageRoute = '/info'
const journeyMapItemRoute = '/journeymap/:id/:extraId'
const journeyMapRoute = '/journeymap'
const loggedOutPageRoute = '/loggedout'
const loginErrorPageRoute = '/welcome/error'
const notFoundPageRoute = '/404'
const notificationsRoute = '/notifications'
const planChangeRoute = '/plan_change'
const somethingHasChangedRoute = '/timeline/something-has-changed'
const thanksPageRoute = '/thanks'
const timelineRoute = '/timeline'
const transitionFlowRoute = '/transition'
const unsupportedPageRoute = '/unsupported'
const userConsentRoute = '/consent'
const userProfileRoute = '/profile'

const managerDashboardRoute = '/manager/dashboard'
const managerJourneyMapRoute = '/manager/journeymap/:absenceId'
const managerDashboardJourneyMapRoute =
  '/manager/dashboard/journeymap/:absenceId'
const managerArchiveJourneyMapRoute = '/manager/archive/journeymap/:absenceId'
const managerPreviousVersionsRoute =
  '/manager/dashboard/previous-versions/:absenceId'
const managerArchiveRoute = '/manager/archive'
const managerNotificationsRoute = '/manager/notifications'
const managerHelpCenterRoute = '/manager/help'

const routesWithFooter = [
  welcomeRoute,
  createLeaveRoute,
  loginErrorPageRoute,
  timelineRoute,
  journeyMapRoute,
  managerDashboardRoute,
  managerJourneyMapRoute,
  managerDashboardJourneyMapRoute,
  managerArchiveJourneyMapRoute,
  managerPreviousVersionsRoute,
  managerArchiveRoute,
  managerHelpCenterRoute,
  hrAdminDashboardRoute,
  userProfileRoute,
  notificationsRoute,
  managerNotificationsRoute,
  helpCenterRoute,
  planChangeRoute,
  claimUpdateRoute,
  errorPageRoute,
  unsupportedPageRoute,
  eligibilityNoticePageRoute,
  getRemindersPageRoute,
  thanksPageRoute,
  outdatedPlanRoute,
  completedPlanRoute,
  userConsentRoute,
  closeAccountRoute,
  somethingHasChangedRoute,
  transitionFlowRoute,
  infoPageRoute
]

const routesWithoutMenu = [loggedOutPageRoute, userConsentRoute]

const routesWithoutBanner = [
  loginErrorPageRoute,
  loggedOutPageRoute,
  welcomeRoute,
  userConsentRoute,
  errorPageRoute,
  closeAccountRoute,
  unsupportedPageRoute,
  unsupportedPageRoute + '/:hash'
]

// Whitelisted routes to work with deepLinks mechanism
const routesForDeepLinks = [
  createLeaveRoute,
  timelineRoute,
  managerDashboardRoute,
  managerJourneyMapRoute,
  managerDashboardJourneyMapRoute,
  managerArchiveJourneyMapRoute,
  managerPreviousVersionsRoute,
  managerArchiveRoute,
  managerHelpCenterRoute,
  hrAdminDashboardRoute,
  userProfileRoute,
  notificationsRoute,
  managerNotificationsRoute,
  helpCenterRoute,
  claimUpdateRoute,
  closeAccountRoute,
  somethingHasChangedRoute,
  globalSurveysRoute,
  journeyMapItemRoute
]

export {
  claimUpdateRoute,
  closeAccountRoute,
  completedPlanRoute,
  createLeaveRoute,
  eligibilityNoticePageRoute,
  errorPageRoute,
  getRemindersPageRoute,
  globalSurveysRoute,
  helpCenterRoute,
  hrAdminDashboardRoute,
  infoPageRoute,
  journeyMapItemRoute,
  journeyMapRoute,
  loggedOutPageRoute,
  loginErrorPageRoute,
  managerArchiveRoute,
  managerDashboardRoute,
  managerHelpCenterRoute,
  managerNotificationsRoute,
  managerJourneyMapRoute,
  managerDashboardJourneyMapRoute,
  managerArchiveJourneyMapRoute,
  managerPreviousVersionsRoute,
  notFoundPageRoute,
  notificationsRoute,
  outdatedPlanRoute,
  planChangeRoute,
  routesForDeepLinks,
  routesWithFooter,
  routesWithoutBanner,
  routesWithoutMenu,
  somethingHasChangedRoute,
  thanksPageRoute,
  timelineRoute,
  transitionFlowRoute,
  unsupportedPageRoute,
  userConsentRoute,
  userProfileRoute,
  welcomeRoute
}
