import React, {
  Fragment,
  MutableRefObject,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { isAndroid, isIOS } from 'src/utils/deviceTypes'
import zIndex from 'src/constants/zIndex'
import PopoverArrow from 'src/components/Popover/components/Arrow'
import useComponentRect from 'src/components/hooks/useComponentRect'
import ScreenContext from 'src/contexts/ScreenContext'

interface DropdownOption {
  value: string
  text: string
  icon?: ReactNode
}

interface IProps {
  name: string
  title: string
  noteText?: string
  onChange?: (value: DropdownOption) => void
  options: DropdownOption[]
  selected?: DropdownOption
  placeholder: string
  optionsRef?: MutableRefObject<any[]>
  disabledOptionIndexes?: number[]
  displayBlock?: boolean
}

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'select'
    'note';
  z-index: ${zIndex.dropDown.wrapper};
  width: 100%;
  grid-auto-rows: 16px auto auto;
`

export const TitleWrapper = styled.span`
  grid-area: title;
  color: ${props => props.theme.colors.dark60};
  font-size: 14px;
`

const SelectWrapper = styled.button<{ $isOpen: boolean; $focused: boolean }>`
  grid-area: select;
  width: 100%;
  background: ${props => props.theme.colors.light100};
  margin: 6px 0 0;
  position: relative;
  height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
  outline: none;
  transition: all 100ms;
  border: 1px solid
    ${props =>
      props.$focused ? props.theme.colors.main110 : props.theme.colors.dark50};

  &:focus {
    border: 1px solid ${props => props.theme.colors.main110};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.main110};
    outline: none;
  }

  overflow: ${props => (props.$isOpen ? `none` : `hidden`)};
`

const MobileSelect = styled.select<{ $focused: boolean }>`
  grid-area: select;
  padding: 0 12px;
  background: transparent;
  margin: 8px 0 0;
  position: relative;
  height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: normal;
  line-height: 28px;
  color: ${props => props.theme.colors.dark60};
  font-size: 16px;
  outline: none;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${props => props.theme.colors.main100} 50%
    ),
    linear-gradient(
      135deg,
      ${props => props.theme.colors.main100} 50%,
      transparent 50%
    );
  background-position:
    calc(100% - 18px) 17px,
    calc(100% - 13px) 17px;
  background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
  appearance: none;
  border-radius: 6px;
  border: 1px solid
    ${props =>
      props.$focused ? props.theme.colors.main100 : props.theme.colors.dark60};

  ${props =>
    isIOS() &&
    css`
      &:focus-within {
        border: 1px solid
          ${props.$focused
            ? props.theme.colors.main100
            : props.theme.colors.dark50};
      }
    `}

  ${props =>
    isAndroid() &&
    css`
      &:focus-within {
        border: 1px solid
          ${props.$focused
            ? props.theme.colors.main100
            : props.theme.colors.dark60};
      }

      &:active {
        background-image: linear-gradient(
            135deg,
            transparent 50%,
            ${props.theme.colors.main100} 50%
          ),
          linear-gradient(
            45deg,
            ${props.theme.colors.main100} 50%,
            transparent 50%
          );
      }
    `}
`

export const PlaceholderWrapper = styled.span<{
  $isPlaceholder?: boolean
  $displayBlock?: boolean
}>`
  display: ${props => (props.$displayBlock ? 'block' : 'flex')};
  align-items: center;
  font-weight: normal;
  line-height: 100%;
  z-index: ${zIndex.dropDown.placeholderWrapper};
  padding: 1px 12px;
  position: relative;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  color: ${props =>
    props.$isPlaceholder
      ? props.theme.colors.dark60
      : props.theme.colors.dark80};
`

export const OptionsContainerWrapper = styled.div<{ $showsOnTop?: boolean }>`
  background: ${props => props.theme.colors.light100};
  z-index: ${zIndex.dropDown.optionsContainer};
  position: absolute;
  width: 100%;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  left: -1px;
  border-radius: 6px;

  ${props =>
    props.$showsOnTop
      ? css`
          margin-bottom: 50px;
          bottom: -1px;
        `
      : css`
          margin-top: 50px;
          top: -1px;
        `}
`

const OptionsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border: 1px solid ${props => props.theme.colors.dark50};
`

export const OptionWrapper = styled.div<{
  $selected?: boolean
  $disabled?: boolean
  $showsOnTop?: boolean
}>`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.dark80};
  background: ${props =>
    props.$selected ? props.theme.colors.main10 : props.theme.colors.light100};
  user-select: none;
  font-weight: normal;
  font-size: 16px;
  padding: 12px 0 12px 12px;
  margin: 0;
  text-align: left;
  outline: none;

  ${p =>
    p.$disabled &&
    css`
      color: ${props => props.theme.colors.dark20};
      pointer-events: none;
    `}

  &:hover {
    background: ${props => props.theme.colors.main100};
    color: ${props => props.theme.colors.light100};
  }

  &:focus {
    background: ${props => props.theme.colors.main100};
    color: ${props => props.theme.colors.light100};
    outline: none;
  }

  ${props =>
    props.$showsOnTop
      ? css`
          &:first-child {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }
        `
      : css`
          &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        `}
`

export const NoteWrapper = styled.p`
  grid-area: note;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  margin-top: 8px;
  color: ${props => props.theme.colors.dark60};
`

export const PopoverArrowWrapper = styled(PopoverArrow)`
  grid-area: select;
  place-self: center end;
  margin-right: 10px;
  margin-top: ${props => (props.theme.isMobile ? '5px' : '10px')};
  cursor: pointer;
  z-index: ${zIndex.dropDown.arrow};
`

export const dataAttrs = {
  dropdown: () => 'dropdown',
  option: () => 'dropdown-option',
  mobile: () => 'dropdown-mobile'
}

export const Dropdown = React.memo((props: IProps) => {
  const {
    options,
    optionsRef,
    selected,
    placeholder,
    title,
    noteText,
    name,
    disabledOptionIndexes,
    displayBlock
  } = props
  const wrapperRef = useRef(null)
  const optionsContainerRef = useRef(null)
  const selectRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [focused, setFocused] = useState(false)
  const [showsOnTop, setShowsOnTop] = useState(false)
  const [documentHeight, setDocumentHeight] = useState(0)
  const { bottom } = useComponentRect(wrapperRef)
  const optionsContainerHeight: number =
    useComponentRect(optionsContainerRef).height
  const { isMobile } = useContext(ScreenContext)

  useEffect(() => {
    const onDocumentClick = (event: Event) => {
      const { current } = wrapperRef
      if (!current) {
        return
      }

      if (current && !current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', onDocumentClick)

    return () => {
      document.removeEventListener('click', onDocumentClick)
    }
  }, [])

  useEffect(() => {
    const onResize = () => {
      setDocumentHeight(document.documentElement.clientHeight)
    }

    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    setShowsOnTop(documentHeight - optionsContainerHeight - bottom <= 0)
  }, [bottom, documentHeight, optionsContainerHeight, isOpen])

  const onClick = useCallback((): any => {
    setIsOpen(!isOpen)

    if (isOpen) {
      return
    }

    if (isMobile && selectRef.current) {
      const event: any = document.createEvent('MouseEvents')
      event.initMouseEvent('mousedown', true, true, window)
      selectRef.current.dispatchEvent(event)
    }
  }, [isMobile, isOpen])

  const onChange = useCallback(
    (option: DropdownOption) => props.onChange(option),
    [props]
  )

  const onChangeMobile = useCallback(
    (event: React.SyntheticEvent<HTMLSelectElement, Event>) => {
      const target: HTMLSelectElement = event.target as HTMLSelectElement
      const option = options.find(
        (o: DropdownOption) => o.text === target.value
      )
      props.onChange(option)
      setIsOpen(false)
      selectRef.current.blur()
    },
    [props, options]
  )

  const optionsView: ReactNode = useMemo(
    () =>
      options.map((opt, index) => {
        const disabled: boolean = disabledOptionIndexes
          ? disabledOptionIndexes.indexOf(index) !== -1
          : false
        return (
          <OptionWrapper
            data-testid={dataAttrs.option()}
            key={opt.value}
            ref={(el: any) =>
              optionsRef ? (optionsRef.current[index] = el) : null
            }
            $showsOnTop={showsOnTop}
            onClick={() => {
              onChange(opt)
            }}
            $disabled={disabled}
            onKeyDown={(event: SyntheticEvent) => {
              const { keyCode } = event as any
              if (keyCode === 13 || keyCode === 32) {
                onChange(opt)
              }
            }}
            tabIndex={disabled ? -1 : 0}
          >
            {opt.icon}
            {opt.text}
          </OptionWrapper>
        )
      }),
    [disabledOptionIndexes, onChange, options, showsOnTop, optionsRef]
  )

  const mobileOptionsView: ReactNode[] = useMemo(() => {
    const opts: any[] = []
    opts.push(
      <option
        value=""
        disabled
        hidden
        aria-selected={false}
        key={'placeholder'}
      >
        {placeholder}
      </option>
    )

    options.forEach((opt, index) => {
      opts.push(
        <option
          key={opt.value}
          aria-selected={opt === selected}
          disabled={
            disabledOptionIndexes
              ? disabledOptionIndexes.indexOf(index) !== -1
              : false
          }
        >
          {opt.icon}
          {opt.text}
        </option>
      )
    })

    return opts
  }, [disabledOptionIndexes, options, placeholder, selected])

  const optionsContainerView: ReactNode = useMemo(
    () => (
      <OptionsContainerWrapper
        $showsOnTop={showsOnTop}
        ref={optionsContainerRef}
      >
        <OptionsContainer>{optionsView}</OptionsContainer>
      </OptionsContainerWrapper>
    ),
    [optionsView, showsOnTop]
  )

  const textView: ReactNode = useMemo(() => {
    const text = selected?.text || placeholder
    return (
      <PlaceholderWrapper
        $isPlaceholder={!selected}
        $displayBlock={displayBlock}
      >
        {selected?.icon}
        {text}
      </PlaceholderWrapper>
    )
  }, [selected, placeholder, displayBlock])

  const arrowView: ReactNode = useMemo(
    () => <PopoverArrowWrapper isOpen={isOpen} pointerEvents={'none'} />,
    [isOpen]
  )

  const isMobileIosOrAndroid: boolean = useMemo(
    () => isMobile && (isIOS() || isAndroid()),
    [isMobile]
  )

  return (
    <Wrapper ref={wrapperRef}>
      <TitleWrapper>{title}</TitleWrapper>
      {isMobileIosOrAndroid && (
        <MobileSelect
          data-testid={dataAttrs.mobile()}
          name={name}
          ref={selectRef}
          value={selected?.text || ''}
          $focused={focused || isOpen}
          onChange={onChangeMobile}
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() => {
            setFocused(false)
          }}
        >
          {mobileOptionsView}
        </MobileSelect>
      )}
      {noteText && <NoteWrapper>{noteText}</NoteWrapper>}
      {!isMobileIosOrAndroid && (
        <Fragment>
          <SelectWrapper
            data-testid={dataAttrs.dropdown()}
            onClick={onClick}
            $focused={focused || isOpen}
            $isOpen={isOpen}
          >
            {textView}
            {isOpen && optionsContainerView}
          </SelectWrapper>
          {arrowView}
        </Fragment>
      )}
    </Wrapper>
  )
})

Dropdown.displayName = 'Dropdown'

export default Dropdown
