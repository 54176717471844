import React, { useContext, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { UIText, Button } from 'src/UIKit'
import AdjustInTheTimeline from 'src/components/AdjustInTheTimeline'
import CheckboxConfirmationView from 'src/components/CheckboxConfirmationView'
import ScreenContext from 'src/contexts/ScreenContext'
import AbsenceTimeline from 'src/features/ManagerJourneyMap/components/AbsenceTimeline'

interface IProps {
  className?: any
  leave: ILeave
  managerActivated: boolean
  email: string
  onButtonClick: () => void
}

const Container = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 16px 24px 24px;
        `
      : css`
          padding: 16px;
        `}
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${props =>
    props.theme.isDesktop
      ? css`
          align-items: center;
        `
      : css`
          align-items: flex-start;
        `}
`

const StatusContainer = styled.div`
  padding-left: 8px;
  display: flex;
  align-items: center;
`

const StatusText = styled.span<{ $activated: boolean }>`
  font-style: normal;
  line-height: 150%;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
          margin-right: 8px;
          font-weight: 500;
          color: ${props.theme.colors.dark80};
        `
      : css`
          text-align: right;
          font-size: 14px;
          margin-right: 5px;
          font-weight: 700;
          color: ${props.theme.colors.dark60};
          ${!props.$activated &&
          css`
            width: 86px;
          `}
        `}
`

const StatusIndicator = styled.div<{ $activated: boolean }>`
  border-radius: 50%;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 16px;
          height: 16px;
        `
      : css`
          width: 10px;
          height: 10px;
        `}
  background: ${props =>
    props.$activated
      ? props.theme.colors.success100
      : props.theme.colors.dark50};
`

const NotAccurateText = styled(UIText)`
  color: ${props => props.theme.colors.dark60};
  margin-top: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 12px;
        `}
`

const RowsContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props =>
    props.theme.isDesktop &&
    css`
      width: 348px;
      padding: 0 20px;
    `}
`

const ButtonWrapper = styled(Button)`
  display: block;
  margin: 24px auto 0;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 276px;
        `
      : css`
          width: 100%;
        `}
`

const TimelineTitle = styled.div`
  font-weight: 500;
  line-height: 150%;
  margin: 16px auto 12px;
  color: ${props => props.theme.colors.dark60};
  text-align: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
        `
      : css`
          font-size: 14px;
        `}
`

const ShareWithManagerView = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const { className, leave, managerActivated, email, onButtonClick } = props
    const { t } = useTranslation()
    const [confirmed, setConfirmed] = useState(false)
    const statusText: string = t(
      `sharingSettings.statuses.${
        managerActivated ? 'activated' : 'notActivated'
      }`
    )

    const employeeAbsence = useMemo(() => {
      if (!leave?.managerActivationInfo) {
        return null
      }

      const info = leave?.managerActivationInfo

      return {
        startDate: info.startDate,
        endDate: info.endDate,
        timeline: info.timeline
      } as IAbsence
    }, [leave])

    const { i18Context } = useContext(ScreenContext)

    return (
      <Container className={className} ref={ref}>
        <HeaderContainer>
          <UIText>
            {t(`sharingSettings.managerEmail`, {
              email
            })}
          </UIText>
          <StatusContainer>
            <StatusText $activated={managerActivated}>{statusText}</StatusText>
            <StatusIndicator $activated={managerActivated} />
          </StatusContainer>
        </HeaderContainer>
        {!managerActivated && (
          <React.Fragment>
            <NotAccurateText allowsParagraph>
              {t('sharingSettings.managerListedIsNotAccurate', {
                context: i18Context,
                email
              })}
            </NotAccurateText>
          </React.Fragment>
        )}
        {employeeAbsence && (
          <>
            <TimelineTitle>
              {t('sharingSettings.intoToShareWithManager')}
            </TimelineTitle>
            <RowsContainer>
              <AbsenceTimeline absence={employeeAbsence} showCurrent={false} />
              {!managerActivated && (
                <AdjustInTheTimeline
                  translationKey={'sharingSettings.adjustDates'}
                />
              )}
            </RowsContainer>
          </>
        )}
        {!managerActivated && (
          <React.Fragment>
            <CheckboxConfirmationView
              translationKey={'sharingSettings.confirmation'}
              onChange={() => {
                setConfirmed(!confirmed)
              }}
              confirmed={confirmed}
            />
            <ButtonWrapper onClick={onButtonClick} disabled={!confirmed}>
              {t('sharingSettings.activate')}
            </ButtonWrapper>
          </React.Fragment>
        )}
      </Container>
    )
  })
)

ShareWithManagerView.displayName = 'ShareWithManagerView'

export default ShareWithManagerView
