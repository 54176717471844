import React from 'react'
import styled, { css } from 'styled-components'
import ItemListTitled from 'src/components/ItemListTitled'
import { useTranslation } from 'react-i18next'
import { convertTpaChangeItems } from 'src/utils/periodUtils'

interface IProps {
  className?: any
  leaveType: ILeaveType
  changes: ITpaChange
}

const Container = styled.div`
  width: 100%;
`

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${props =>
    props.theme.isDesktop
      ? css`
          flex-direction: row;
          gap: 6px;
          padding-bottom: 16px;
          margin: 12px 0 0;
        `
      : css`
          flex-direction: column;
          gap: 16px;
        `}
`

const ItemListTitledWrapper = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 365px;
        `
      : css`
          width: 100%;
        `}
`

const ChangeList = React.memo((props: IProps) => {
  const { className, changes, leaveType } = props
  const { t } = useTranslation()

  const previousItems = convertTpaChangeItems(changes.previous, leaveType, t)
  const currentItems = convertTpaChangeItems(changes.current, leaveType, t)

  return (
    <Container className={className}>
      <ItemsContainer
        role={'region'}
        aria-label={t('common.accessibilityText.planChangesRegion')}
      >
        <ItemListTitledWrapper>
          <ItemListTitled
            title={t('planChange.previousPlanDetails')}
            items={previousItems}
          />
        </ItemListTitledWrapper>
        <ItemListTitledWrapper>
          <ItemListTitled
            title={t('planChange.currentPlanDetails')}
            items={currentItems}
          />
        </ItemListTitledWrapper>
      </ItemsContainer>
    </Container>
  )
})

ChangeList.displayName = 'ChangeList'

export default ChangeList
