import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import { withDialogManager } from 'src/components/DialogManager'
import { useTranslation } from 'react-i18next'
import { IDialogData, IWithDialogManager } from 'src/react-app-env'
import VacationReportButton from './components/VacationReportButton'
import VacationDialogContent from './components/VacationDialogContent'
import VacationOverviewContent from './components/VacationOverviewContent'
import usePrevious from 'src/components/hooks/usePrevious'
import ScreenContext from 'src/contexts/ScreenContext'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import { VACATION_OVERVIEW_KEY } from 'src/utils/ls'

interface IProps extends IWithDialogManager {
  className?: string
  disabled?: boolean
  metadata: any
  onVacationBalanceChanged: (vacationBalance: number) => void
  onShowMobile?: () => void
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const VacationBalanceEditor = React.memo((props: IProps) => {
  const {
    className,
    disabled,
    dialogManager,
    metadata,
    onVacationBalanceChanged,
    onShowMobile
  } = props

  const {
    PTOBalance,
    estimatedNextMaxAccrualDate,
    maxAccrualNotice,
    maxAccrualDate,
    plannedVacationDays,
    plannedVacationHours,
    recommendedDaysBeforeLeaveStart,
    recommendedDaysBeforeDisability,
    recommendedDaysBeforeBonding
  } = metadata || {}
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)
  const wasMobile: boolean = usePrevious(isMobile)
  const previousVacationHours: number = usePrevious(PTOBalance)
  const [showVacationOverview, setShowVacationOverview] = useLocalStorage(
    VACATION_OVERVIEW_KEY,
    { canBeShown: true }
  )
  const [hasChanges, setHasChanges] = useState<boolean>(false)

  const isSwitchedMobile = useMemo(
    () => !wasMobile && isMobile,
    [isMobile, wasMobile]
  )

  useEffect(() => {
    if (isSwitchedMobile && dialogManager.remove) {
      dialogManager.remove()
    }
  }, [dialogManager, isSwitchedMobile, onShowMobile])

  const onUpdate = useCallback(
    (value: string) => {
      onVacationBalanceChanged(parseFloat(value))
      setHasChanges(true)
    },
    [onVacationBalanceChanged]
  )

  const onCancel = useCallback(() => dialogManager.remove(), [dialogManager])

  const showDialog = useCallback(
    (dialogView: IDialogData) => {
      dialogManager.add(dialogView)
    },
    [dialogManager]
  )

  const dialogVacationView: IDialogData = useMemo(
    () => ({
      title: t('vacationBalance.dialog.title'),
      children: (
        <VacationDialogContent
          vacationHours={PTOBalance?.toFixed(2).toString()}
          onUpdate={(value: string) => {
            onUpdate(value)
          }}
          isMaxAccrual={maxAccrualNotice}
          maxAccrualDate={maxAccrualDate}
          plannedVacationDays={plannedVacationDays}
          plannedVacationHours={plannedVacationHours}
          recommendedDaysStart={recommendedDaysBeforeLeaveStart}
          recommendedDaysDisability={recommendedDaysBeforeDisability}
          recommendedDaysBonding={recommendedDaysBeforeBonding}
          estimatedNextMaxAccrualDate={estimatedNextMaxAccrualDate}
        />
      ),
      showsCloseButtonOnMobile: true
    }),
    [
      t,
      PTOBalance,
      maxAccrualNotice,
      maxAccrualDate,
      plannedVacationDays,
      plannedVacationHours,
      recommendedDaysBeforeLeaveStart,
      recommendedDaysBeforeDisability,
      recommendedDaysBeforeBonding,
      estimatedNextMaxAccrualDate,
      onUpdate
    ]
  )

  const dialogOverviewView: IDialogData = useMemo(
    () => ({
      title: t('vacationBalance.overview.title'),
      children: (
        <VacationOverviewContent
          onShowEdit={() => {
            showDialog(dialogVacationView)
          }}
          onCancel={onCancel}
          onChecked={(check: boolean) => {
            setShowVacationOverview({ canBeShown: check })
          }}
        />
      ),
      showsCloseButtonOnMobile: true
    }),
    [dialogVacationView, onCancel, setShowVacationOverview, showDialog, t]
  )

  useEffect(() => {
    if (isDesktop && hasChanges && PTOBalance !== previousVacationHours) {
      dialogManager.update(dialogVacationView)
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PTOBalance])

  const onClick = useCallback(() => {
    if (disabled) {
      return
    }

    if (isDesktop) {
      showDialog(
        showVacationOverview.canBeShown
          ? dialogOverviewView
          : dialogVacationView
      )
    } else {
      onShowMobile()
    }
  }, [
    dialogOverviewView,
    dialogVacationView,
    disabled,
    isDesktop,
    showDialog,
    onShowMobile,
    showVacationOverview
  ])

  const usedVacationReportButton = useMemo(
    () => (
      <VacationReportButton
        onClick={onClick}
        translationKey={'vacationBalance.vacationAccrual'}
        disabled={disabled}
      />
    ),
    [disabled, onClick]
  )

  return <Container className={className}>{usedVacationReportButton}</Container>
})

VacationBalanceEditor.displayName = 'VacationBalanceEditor'

export default withDialogManager(VacationBalanceEditor)
