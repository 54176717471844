import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { Input } from 'src/UIKit'
import FieldCard from 'src/components/FieldCard'
import {
  validateConfirmationCode,
  validateEmail,
  validatePhone
} from 'src/utils/validators'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { replaceSpecialSymbols } from 'src/utils/stringUtils'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import {
  applyFieldErrorsForState,
  getFieldExpiresIn,
  getFieldUnlocksIn,
  getInputButtonProps,
  isFieldVerified,
  populateFieldsForState
} from 'src/features/UserProfile/components/ProfileForm/methods'
import ScreenContext from 'src/contexts/ScreenContext'
import { getCountryCode } from 'src/utils/userUtils'
import SharedContext from 'src/contexts/SharedContext'

export const PERSONAL_EMAIL = 'personalEmail'
export const PHONE_NUMBER = 'phoneNumber'
export const PERSONAL_EMAIL_CODE = 'personalEmailCode'
export const PHONE_NUMBER_CODE = 'phoneNumberCode'

interface IUserProfileProps {
  className?: any
  user?: IUser
  fieldErrors?: any
  updatePersonalEmail: (value: string) => void
  updatePhoneNumber: (value: string) => void
  sendPersonalEmailConfirmationCode: (value: string) => void
  sendPhoneNumberConfirmationCode: (value: string) => void
  resendConfirmationCode: (kind: string) => void
}

export interface IInputProps {
  value: string
  valid: boolean
  validator?: (value: string, regexp?: string) => any
  touched: boolean
  focused: boolean
  verified?: boolean
  errorString?: string
  placeholder?: string
  mask?: string
  pattern?: string
  fetching?: boolean
  tabIndex?: number
  disabled?: boolean
  maskChar?: string
}

export interface IUserProfileState {
  [key: string]: any
  user?: IUser
  fieldErrors: any
  email: string
  personalEmail: IInputProps
  phoneNumber: IInputProps
  personalEmailCode: IInputProps
  phoneNumberCode: IInputProps
}

export const defaultState: IUserProfileState = {
  user: null,
  fieldErrors: null,
  email: '',

  phoneNumber: {
    value: '',
    valid: false,
    validator: validatePhone,
    touched: false,
    focused: false,
    verified: true,
    errorString: '',
    fetching: false,
    tabIndex: 1,
    disabled: false,
    maskChar: '_'
  },
  phoneNumberCode: {
    value: '',
    valid: false,
    touched: false,
    focused: false,
    validator: validateConfirmationCode,
    placeholder: 'xx-xx',
    pattern: '[0-9]*',
    mask: '99-99',
    fetching: false,
    tabIndex: 2
  },
  personalEmail: {
    value: '',
    valid: false,
    validator: validateEmail,
    touched: false,
    focused: false,
    verified: true,
    errorString: '',
    fetching: false,
    placeholder: 'your@email.com',
    tabIndex: 3,
    disabled: false
  },
  personalEmailCode: {
    value: '',
    valid: false,
    touched: false,
    focused: false,
    validator: validateConfirmationCode,
    placeholder: 'xx-xx',
    mask: '99-99',
    pattern: '[0-9]*',
    fetching: false,
    tabIndex: 4
  }
}

const getGridTemplateAreas = (props: any) => {
  if (props.theme.isDesktop) {
    const r1 = props.$showsPhoneCode
      ? `${PHONE_NUMBER} ${PHONE_NUMBER_CODE}`
      : `${PHONE_NUMBER} ${PHONE_NUMBER}`
    const r2 = props.$showsEmailCode
      ? `${PERSONAL_EMAIL} ${PERSONAL_EMAIL_CODE}`
      : `${PERSONAL_EMAIL} ${PERSONAL_EMAIL}`
    return "'" + r1 + "' '" + r2 + "'"
  } else {
    return `'${PHONE_NUMBER}' '${PHONE_NUMBER_CODE}' '${PERSONAL_EMAIL}' '${PERSONAL_EMAIL_CODE}'`
  }
}

const GridWrapper = styled.form<{
  $showsPhoneCode: boolean
  $showsEmailCode: boolean
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  grid-template-areas: ${(props: any) => getGridTemplateAreas(props)};

  ${props =>
    props.theme.isDesktop &&
    css`
      grid-template-columns: auto 158px;
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 16px;
    `}
`

const FieldCardWrapper = styled(FieldCard)<{ $gridArea: string }>`
  grid-area: ${props => props.$gridArea};

  ${props =>
    props.theme.isDesktop
      ? css`
          &:nth-child(n + 3) {
            margin-top: 12px;
          }
        `
      : css`
          &:not(:first-child) {
            margin-top: 12px;
          }
        `}
`

const UnlocksInWrapper = styled.div`
  font-weight: normal;
  padding: 0;
  margin: 0;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          line-height: 14px;
        `
      : css`
          font-size: 12px;
          line-height: 12px;
        `}
  color: ${props => props.theme.colors.dark60};

  span {
    font-weight: 600;
  }
`

let forceUpdateTimeoutId: number

const ProfileForm = React.memo((props: IUserProfileProps) => {
  const {
    className,
    user: currentUser,
    resendConfirmationCode,
    updatePhoneNumber,
    updatePersonalEmail,
    sendPersonalEmailConfirmationCode,
    sendPhoneNumberConfirmationCode
  } = props
  const { customerConfig } = useContext(SharedContext)
  const {
    userProfile: { profileSection }
  } = customerConfig

  const { t } = useTranslation()

  const forceUpdate = useForceUpdate()
  const [state, setState] = useState<IUserProfileState>(defaultState)
  const { phoneNumber, personalEmail } = state

  phoneNumber.mask = t('userProfile.phoneSettings.phoneNumberMask')
  phoneNumber.placeholder = t(
    'userProfile.phoneSettings.phoneNumberPlaceholder'
  )

  const inputRefs: any = {
    [PHONE_NUMBER]: useRef(null),
    [PERSONAL_EMAIL]: useRef(null),
    [PHONE_NUMBER_CODE]: useRef(null),
    [PERSONAL_EMAIL_CODE]: useRef(null)
  }

  const countryCode = useMemo(() => getCountryCode(currentUser), [currentUser])
  const phoneRegexp = useMemo(
    () => t('userProfile.phoneSettings.phoneRegexp'),
    [t]
  )

  useEffect(() => {
    clearTimeout(forceUpdateTimeoutId)

    const hasLockedCode: boolean =
      getFieldUnlocksIn(PERSONAL_EMAIL, state) > 0 ||
      getFieldUnlocksIn(PHONE_NUMBER, state) > 0

    // if one of the codes is locked, update every second
    if (hasLockedCode) {
      forceUpdateTimeoutId = window.setTimeout(() => {
        forceUpdate()
      }, 1000)

      return
    }

    const emailExpiresIn: number = getFieldExpiresIn(PERSONAL_EMAIL, state)
    const phoneExpiresIn: number = getFieldExpiresIn(PHONE_NUMBER, state)

    const values: number[] = []

    if (emailExpiresIn > 0) {
      values.push(emailExpiresIn)
    }
    if (phoneExpiresIn > 0) {
      values.push(phoneExpiresIn)
    }
    if (values.length === 0) {
      return
    }

    const expiresTimeout: number = Math.min(...values)
    if (expiresTimeout < 0) {
      return
    }
    // if one of the codes is not confirmed yet, update after a second after it becomes expired
    forceUpdateTimeoutId = window.setTimeout(
      () => {
        forceUpdate()
      },
      expiresTimeout * 1000 + 1000
    )
  })

  useEffect(
    () => () => {
      clearTimeout(forceUpdateTimeoutId)
    },
    []
  )

  useEffect(() => {
    const newUser: IUser = props.user
    const newFieldErrors: any = props.fieldErrors
    const newState: IUserProfileState = cloneDeep(state)

    if (newUser && !isEqual(newUser, state.user)) {
      populateFieldsForState(newUser, newState, defaultState, t)
    }

    if (!isEqual(newFieldErrors, state.fieldErrors)) {
      applyFieldErrorsForState(newFieldErrors, newState)
    }

    setState(newState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const submitCodeIfPossible = useCallback(
    (name: string, inputProps: IInputProps) => {
      if (!inputProps.validator(inputProps.value, phoneRegexp).valid) {
        return
      }

      if (name === PERSONAL_EMAIL_CODE) {
        sendPersonalEmailConfirmationCode(inputProps.value)
      } else if (name === PHONE_NUMBER_CODE) {
        sendPhoneNumberConfirmationCode(inputProps.value)
      }

      const ref: any = inputRefs[name].current
      if (ref) {
        const input: any = ref.getInputDOMNode()
        input.blur()
      }

      const newState = cloneDeep(state)
      newState[name] = {
        ...inputProps,
        value: '',
        focused: false,
        fetching: true
      }
      setState(newState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      sendPhoneNumberConfirmationCode,
      sendPersonalEmailConfirmationCode,
      setState,
      inputRefs
    ]
  )

  useEffect(() => {
    submitCodeIfPossible(PHONE_NUMBER_CODE, state.phoneNumberCode)
    submitCodeIfPossible(PERSONAL_EMAIL_CODE, state.personalEmailCode)
  }, [state.phoneNumberCode, state.personalEmailCode, submitCodeIfPossible])

  const onInputLostFocus = useCallback(
    (name: string) => {
      const inputProps: IInputProps = { ...state[name] }
      if (inputProps.fetching || !inputProps.focused) {
        return
      }
      if (inputProps.value) {
        inputProps.touched = true
      }
      inputProps.focused = false
      inputProps.errorString = inputProps.validator(
        inputProps.value,
        phoneRegexp
      ).errorString

      const newState = cloneDeep(state)
      newState[name] = inputProps
      setState(newState)
    },
    [state, setState, phoneRegexp]
  )

  const onInputFocus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = event.target
      const inputProps: IInputProps = { ...state[name] }
      inputProps.focused = true
      if (inputProps.touched && !inputProps.value) {
        inputProps.touched = false
      }
      const newState = cloneDeep(state)
      newState[name] = inputProps
      setState(newState)
    },
    [state, setState]
  )

  const onInputBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputLostFocus(event.target.name)
    },
    [onInputLostFocus]
  )

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target
      const inputProps: IInputProps = { ...state[name] }
      inputProps.value = value
      if (inputProps.validator) {
        const result = inputProps.validator(value, phoneRegexp)
        inputProps.valid = result.valid
        inputProps.errorString = result.errorString || null
      }

      const newState = cloneDeep(state)
      newState[name] = inputProps
      setState(newState)
    },
    [state, setState, phoneRegexp]
  )

  const inputPropsMakeFetching = useCallback(
    (name: string) => {
      const inputProps: IInputProps = { ...state[name], fetching: true }
      const newState = cloneDeep(state)
      newState[name] = inputProps
      setState(newState)
    },
    [state, setState]
  )

  const submitUserValue = useCallback(
    (name: string, value: string) => {
      let method: any
      if (name === PHONE_NUMBER) {
        method = updatePhoneNumber
      } else if (name === PERSONAL_EMAIL) {
        method = updatePersonalEmail
      }
      method(value)
      inputPropsMakeFetching(name)
    },
    [updatePhoneNumber, updatePersonalEmail, inputPropsMakeFetching]
  )

  const resendConfirmation = useCallback(
    (kind: string) => {
      resendConfirmationCode(kind)
    },
    [resendConfirmationCode]
  )

  const renderInput = useCallback(
    (type: string, name: string, data: any, verifiedString: string = null) => {
      const invalid = data.touched && !data.valid
      let mask: string = data.mask
      let value: string = data.value
      let maskChar: string = state.phoneNumber.maskChar

      if (name === 'phoneNumber') {
        const phoneNumberValue = replaceSpecialSymbols(data.value)
        if (phoneNumberValue === '1') {
          mask = null
          value = ''
        }

        if (profileSection.hasDynamicPhoneMask(countryCode)) {
          maskChar = ''
          if (userPhoneExists) {
            value = '00' + phoneNumberValue
          }
        }
      }

      return (
        <Input
          type={type}
          name={name}
          value={value}
          placeholder={data.placeholder}
          focused={data.focused}
          verified={data.verified}
          verifiedString={verifiedString}
          invalid={invalid}
          loading={data.fetching}
          errorString={data.errorString}
          mask={mask}
          pattern={data.pattern}
          disabled={data.disabled}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onChange={onInputChange}
          button={getInputButtonProps(
            submitUserValue,
            resendConfirmation,
            state,
            name,
            data,
            currentUser
          )}
          ref={inputRefs[name]}
          alt={data.alt}
          isConfirmationCode={data.isConfirmationCode}
          maskChar={maskChar}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUser,
      inputRefs,
      onInputBlur,
      onInputChange,
      onInputFocus,
      submitUserValue,
      resendConfirmation,
      state
    ]
  )

  const userEmailExists: boolean = useMemo(
    () => currentUser && !!currentUser.personalEmail,
    [currentUser]
  )
  const userPhoneExists: boolean = useMemo(
    () => currentUser && !!currentUser.phoneNumber,
    [currentUser]
  )
  const isEmailVerified: boolean = useMemo(
    () => isFieldVerified(PERSONAL_EMAIL, state),
    [state]
  )
  const isPhoneVerified: boolean = useMemo(
    () => isFieldVerified(PHONE_NUMBER, state),
    [state]
  )
  const showsPhoneCode: boolean = useMemo(
    () => !isPhoneVerified && userPhoneExists && phoneNumber.valid,
    [isPhoneVerified, userPhoneExists, phoneNumber]
  )
  const showsEmailCode: boolean = useMemo(
    () => !isEmailVerified && userEmailExists && personalEmail.valid,
    [isEmailVerified, userEmailExists, personalEmail]
  )

  const { isDesktop } = useContext(ScreenContext)

  const renderUnlocksIn = useCallback(
    (unlocksIn: number) => {
      const m: Moment = moment(unlocksIn * 1000)
      const minutes = m.format('mm')
      const seconds = m.format('ss')
      const time = `${minutes}:${seconds}`

      const ariaMinutes = `${minutes} ${t('common.minute', {
        count: parseInt(minutes, 10)
      })}`

      const aria = `${t('common.maxAttemptsNumberExceeded')}, ${t(
        'common.lockedOutForNext'
      )} ${ariaMinutes}`

      return (
        <UnlocksInWrapper role="status" aria-label={aria}>
          <div aria-hidden>{t('common.maxAttemptsNumberExceeded')}</div>
          <div aria-hidden>
            {t('common.lockedOutForNext')}
            <span role="timer">{time}</span>
          </div>
        </UnlocksInWrapper>
      )
    },
    [t]
  )

  const renderConfirmationCode = useCallback(
    (
      fieldName: string,
      confirmationFieldName: string,
      codeInputPropsName: string
    ) => {
      const unlocksIn: any = getFieldUnlocksIn(fieldName, state)
      const content: any =
        unlocksIn <= 0 ? (
          <React.Fragment>
            {renderInput('tel', confirmationFieldName, {
              ...state[codeInputPropsName],
              alt: t(
                `common.${
                  confirmationFieldName === 'phoneNumberCode'
                    ? 'mobileNumberConfirmationCode'
                    : 'personalEmailConfirmationCode'
                }`
              ),
              isConfirmationCode: true
            })}
          </React.Fragment>
        ) : (
          renderUnlocksIn(unlocksIn)
        )

      return (
        <FieldCardWrapper
          $gridArea={confirmationFieldName}
          label={t('common.confirmationCode')}
          addsLeftMargin={!isDesktop}
          ariaHidden
        >
          {content}
        </FieldCardWrapper>
      )
    },
    [t, state, renderInput, renderUnlocksIn, isDesktop]
  )

  const phoneNumberView: ReactNode = useMemo(() => {
    const field: string = t('common.mobileNumber')
    return (
      <FieldCardWrapper
        $gridArea={PHONE_NUMBER}
        label={`${field}`}
        icon={{ iconName: 'mobile' }}
        ariaHidden
      >
        {renderInput(
          'tel',
          PHONE_NUMBER,
          phoneNumber,
          t('common.fieldVerified', { field })
        )}
      </FieldCardWrapper>
    )
  }, [phoneNumber, t, renderInput])

  const phoneNumberCodeView: ReactNode = useMemo(() => {
    if (!showsPhoneCode) {
      return isDesktop ? <div /> : null
    }

    return renderConfirmationCode(
      PHONE_NUMBER,
      PHONE_NUMBER_CODE,
      'phoneNumberCode'
    )
  }, [renderConfirmationCode, showsPhoneCode, isDesktop])

  const personalEmailView: ReactNode = useMemo(() => {
    const field: string = t('common.personalEmail')
    return (
      <FieldCardWrapper
        $gridArea={PERSONAL_EMAIL}
        label={`${field}`}
        icon={{ iconName: 'mail' }}
        ariaHidden
      >
        {renderInput(
          'email',
          PERSONAL_EMAIL,
          personalEmail,
          t('common.fieldVerified', { field })
        )}
      </FieldCardWrapper>
    )
  }, [personalEmail, renderInput, t])

  const renderPersonalEmailCode: ReactNode = useMemo(() => {
    if (!showsEmailCode) {
      return isDesktop ? <div /> : null
    }

    return renderConfirmationCode(
      PERSONAL_EMAIL,
      PERSONAL_EMAIL_CODE,
      'personalEmailCode'
    )
  }, [showsEmailCode, renderConfirmationCode, isDesktop])

  return (
    <GridWrapper
      $showsPhoneCode={showsPhoneCode}
      $showsEmailCode={showsEmailCode}
      className={className}
      aria-label={t('common.accessibilityText.contactInformation')}
      onSubmit={(event: any) => event.preventDefault()}
      onKeyDown={(event: any) => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      }}
    >
      {phoneNumberView}
      {phoneNumberCodeView}
      {personalEmailView}
      {renderPersonalEmailCode}
    </GridWrapper>
  )
})

ProfileForm.displayName = 'ProfileForm'

export default ProfileForm
