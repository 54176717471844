import React, { ReactNode, useContext, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import { useTranslation } from 'react-i18next'
import CreateLeaveNote from 'src/features/Leave/components/UI/CreateLeaveNote'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import NormalRadioButtonOption from '../NormalRadioButtonOption'
import CheckboxConfirmationView from 'src/components/CheckboxConfirmationView'

const OptionContainer = styled.ul`
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
          max-width: 640px;
          background: ${props.theme.colors.light100};
        `
      : css`
          width: 100%;
        `}
`
const CheckboxConfirmationWrapper = styled(CheckboxConfirmationView)`
  width: 100%;
  margin-top: 32px;
`

export const MedicalBeforeWeStartView = React.memo(() => {
  const {
    prevCreateLeaveStage,
    nextCreateLeaveStage,
    customerLeaveCreateConfig,
    injuryState,
    setInjuryState
  } = useContext<ICreateLeaveContext>(Context)
  const { t } = useTranslation()

  const [filedClaim, setFiledClaim] = useState<boolean>(false)

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={injuryState === 'no'}
        onClick={() => {
          setInjuryState('no')
        }}
      >
        {t(`common.no`)}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={injuryState === 'yes'}
        onClick={() => {
          setInjuryState('yes')
        }}
        hidesSeparator
      >
        {t(`common.yes`)}
      </NormalRadioButtonOption>
    ],
    [t, injuryState, setInjuryState]
  )

  const content: any = useMemo(
    () => (
      <>
        <OptionContainer>{options}</OptionContainer>
        <CreateLeaveNote
          text={t(`createLeave.medical.beforeWeStart.note`)}
          shows={injuryState === 'yes'}
          marginTop={40}
        />
        {customerLeaveCreateConfig.showMedicalFiledClaim &&
          injuryState === 'yes' && (
            <CheckboxConfirmationWrapper
              confirmed={filedClaim}
              translationKey={'createLeave.medical.beforeWeStart.filedMyClaim'}
              onChange={() => {
                setFiledClaim(!filedClaim)
              }}
              fontSize={'16px'}
            />
          )}
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={prevCreateLeaveStage}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={injuryState !== 'no' && !filedClaim}
        />
      </>
    ),
    [
      prevCreateLeaveStage,
      nextCreateLeaveStage,
      t,
      injuryState,
      options,
      filedClaim,
      setFiledClaim,
      customerLeaveCreateConfig.showMedicalFiledClaim
    ]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>
        {t(`createLeave.medical.beforeWeStart.title`)}
      </CreateLeaveTitle>
      <CreateLeaveDescription>
        {t(`createLeave.medical.beforeWeStart.description`)}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

MedicalBeforeWeStartView.displayName = 'MedicalBeforeWeStartView'

export default MedicalBeforeWeStartView
