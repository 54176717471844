import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import useServiceWorker from 'src/components/hooks/useServiceWorker'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import zIndex from 'src/constants/zIndex'
import { header } from 'src/constants/frame'

const Container = styled.div`
  z-index: ${zIndex.updatePrompt};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #71b98e;
  height: ${p => header.height(p.theme).px};
  display: flex;
  align-items: center;
`

const TextsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 24px;
        `
      : css`
          margin: 0 16px;
        `}
`

const textMixin = css`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '10px')};
  line-height: ${props => (props.theme.isDesktop ? '150%' : '130%')};
  color: ${props => props.theme.colors.light90};
`

const TextTop = styled.span`
  ${textMixin};
  font-weight: 500;
`

const TextBottom = styled.span`
  ${textMixin}
`

const RefreshButton = styled(Button)`
  transition: all 300ms;
  background: transparent;
  border: 1px solid ${props => props.theme.colors.light100};
  color: ${props => props.theme.colors.light100};

  &:hover {
    background: ${props => props.theme.colors.light100};
    border: none;
    color: #71b98e;
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 154px;
        `
      : css`
          width: 88px;
          height: 32px;
          font-size: 10px;
          padding: 0 8px;
        `}
`

const CloseButton = styled(Icon)`
  padding: 16px;
  cursor: pointer;
  transition: transform 300ms;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin: 0 24px;
    `}

  &:hover {
    transform: scale(1.2);
  }
`

const UpdateAppPromptView = React.memo(() => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()
  const { t } = useTranslation()
  const [isClosed, setIsClosed] = useState<boolean>(false)
  const userResult: IUseUser = useUser()

  if (isClosed || !isUpdateAvailable) {
    return null
  }

  if (!userResult.user) {
    return null
  }

  return (
    <Container>
      <TextsContainer>
        <TextTop>{t('updateNewVersion.serviceHasBeenUpdated')}</TextTop>
        <TextBottom>{t('updateNewVersion.refreshThePage')}</TextBottom>
      </TextsContainer>
      <RefreshButton onClick={updateAssets}>
        {t('updateNewVersion.button')}
      </RefreshButton>
      <CloseButton
        name={'close_crossing'}
        settings={{ fill: '#fff', width: '12px', height: '12px' }}
        onClick={() => {
          setIsClosed(true)
        }}
        ariaLabel={t('common.accessibilityText.closeButton')}
      />
    </Container>
  )
})

UpdateAppPromptView.displayName = 'UpdateAppPromptView'

export default UpdateAppPromptView
